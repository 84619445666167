import React, { useLayoutEffect} from 'react';
import { Modal } from "antd";
import CloseIcon from "../../images/close-icon.png"
import EditProfile from "../../components/edit-profile"
import ChangePhoneNumber from "../../components/change-phone-number"
import "antd/dist/antd.css";
import "./index.css"

const UpdateUserDetails=(props)=>{


    useLayoutEffect(() => {
        if (props.profileDetailsUpdatePopOpen === true) {
            document.body.style.position = 'fixed';
        }
        else{
            document.body.style.position = 'unset';
        }
        }, [props.profileDetailsUpdatePopOpen]); 

const handleClose = () => {
props.closeUpdateProfileModal();
}

return (
<React.Fragment>
<div id="update-details-wrap">  
        <Modal centered visible={props.profileDetailsUpdatePopOpen} onCancel={handleClose} footer={null} maskClosable={false}
            destroyOnClose={true}
            closable={false}
            className="update-details-modal">
                {props.editScreen == "profile"?
                <div className="">
                    <EditProfile handleClose={handleClose}/>
                </div>
                :
                <div>
                    <ChangePhoneNumber handleClose={handleClose}/>
                </div>
                }
        </Modal>
</div>
    
</React.Fragment>
);
}

export default UpdateUserDetails;