// for DEV
// module.exports = {
//   base_url: "https://api.dev.niulihome.com/",
//   GoogleMapsAPI: "AIzaSyCpUaXRcQq4A4OXVROcFI-orTBcZ-UR9go",
//   paymentlink: "https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=",
//   captchaKey: "6LdOtTwoAAAAAJ5DCFxjVG6Q94s3WudcU3XccBmr",
//   PUBNUB: {
//     publishKey: "pub-c-3297953a-4600-4ab4-af95-e10746c420dc",
//     subscribeKey: "sub-c-438a180a-190a-11eb-a0a1-be9072d3ef6c",
//     secretKey: "sec-c-ZDQzODA0ODEtZjdlMi00N2QzLWFjNWMtOTMzMDViZmFhYmEz",
//     uuid: localStorage.getItem("customer_id"),
//   },
// };
// module.exports = {
//   base_url: "https://api.qa.niulihome.com/",
//   GoogleMapsAPI: "AIzaSyCpUaXRcQq4A4OXVROcFI-orTBcZ-UR9go",
//   paymentlink: "https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=",
//   captchaKey: "6LdOtTwoAAAAAJ5DCFxjVG6Q94s3WudcU3XccBmr",
//   PUBNUB: {
//     publishKey: "pub-c-886f3fff-6a0a-435e-b1d5-3fe827a577c2",
//     subscribeKey: "sub-c-8a561b02-3341-11eb-9d95-7ab25c099cb1",
//     secretKey: "sec-c-MjY1ODA2MmMtNGRhMS00ZmJiLWIzNzMtMzA5Y2IxZDQyZDgz",
//     uuid: localStorage.getItem("customer_id"),
//   },
// };
if (process.env.REACT_APP_STAGE === "Build for dev") {
  module.exports = {
    base_url: "https://api.dev.niulihome.com/",
    GoogleMapsAPI: "AIzaSyCpUaXRcQq4A4OXVROcFI-orTBcZ-UR9go",
    paymentlink: "https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=",
    captchaKey: "6LdOtTwoAAAAAJ5DCFxjVG6Q94s3WudcU3XccBmr",
    PUBNUB: {
      publishKey: "pub-c-3297953a-4600-4ab4-af95-e10746c420dc",
      subscribeKey: "sub-c-438a180a-190a-11eb-a0a1-be9072d3ef6c",
      secretKey: "sec-c-ZDQzODA0ODEtZjdlMi00N2QzLWFjNWMtOTMzMDViZmFhYmEz",
      uuid: localStorage.getItem("customer_id"),
    },
  };
} else if (process.env.REACT_APP_STAGE === "Build for QA") {
  module.exports = {
    base_url: "https://api.qa.niulihome.com/",
    GoogleMapsAPI: "AIzaSyCpUaXRcQq4A4OXVROcFI-orTBcZ-UR9go",
    paymentlink: "https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=",
    captchaKey: "6LdOtTwoAAAAAJ5DCFxjVG6Q94s3WudcU3XccBmr",
    PUBNUB: {
      publishKey: "pub-c-886f3fff-6a0a-435e-b1d5-3fe827a577c2",
      subscribeKey: "sub-c-8a561b02-3341-11eb-9d95-7ab25c099cb1",
      secretKey: "sec-c-MjY1ODA2MmMtNGRhMS00ZmJiLWIzNzMtMzA5Y2IxZDQyZDgz",
      uuid: localStorage.getItem("customer_id"),
    },
  };
} else {
  module.exports = {
    base_url: "https://api.niulihome.com/",
    GoogleMapsAPI: "AIzaSyCpUaXRcQq4A4OXVROcFI-orTBcZ-UR9go",
    paymentlink: "https://oppwa.com/v1/paymentWidgets.js?checkoutId=",
    captchaKey: "6LdOtTwoAAAAAJ5DCFxjVG6Q94s3WudcU3XccBmr",
    PUBNUB: {
      publishKey: "pub-c-b2a2bb99-6779-43db-99ba-578df52ec5c0",
      subscribeKey: "sub-c-37e45bc6-3342-11eb-9713-12bae088af96",
      secretKey: "sec-c-YTA0Nzg1MDEtZTVhZC00ZTA1LWIyMDItZDAwZWYzMzAxOGQ5",
      uuid: localStorage.getItem("customer_id"),
    },
  };
}








// for DEV
// module.exports = {
//   base_url: "https://oz2b40r5u6.execute-api.ap-south-1.amazonaws.com/dev/",
//   GoogleMapsAPI: "AIzaSyCpUaXRcQq4A4OXVROcFI-orTBcZ-UR9go",
//   paymentlink: "https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=",
//   captchaKey: "6LdOtTwoAAAAAJ5DCFxjVG6Q94s3WudcU3XccBmr",
//   PUBNUB: {
//     publishKey: "pub-c-3297953a-4600-4ab4-af95-e10746c420dc",
//     subscribeKey: "sub-c-438a180a-190a-11eb-a0a1-be9072d3ef6c",
//     secretKey: "sec-c-ZDQzODA0ODEtZjdlMi00N2QzLWFjNWMtOTMzMDViZmFhYmEz",
//     uuid: localStorage.getItem("customer_id"),
//   },
// };
// module.exports = {
//   base_url: "https://b03qxj9nsi.execute-api.ap-south-1.amazonaws.com/qa/",
//   GoogleMapsAPI: "AIzaSyCpUaXRcQq4A4OXVROcFI-orTBcZ-UR9go",
//   paymentlink: "https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=",
//   captchaKey: "6LdOtTwoAAAAAJ5DCFxjVG6Q94s3WudcU3XccBmr",
//   PUBNUB: {
//     publishKey: "pub-c-886f3fff-6a0a-435e-b1d5-3fe827a577c2",
//     subscribeKey: "sub-c-8a561b02-3341-11eb-9d95-7ab25c099cb1",
//     secretKey: "sec-c-MjY1ODA2MmMtNGRhMS00ZmJiLWIzNzMtMzA5Y2IxZDQyZDgz",
//     uuid: localStorage.getItem("customer_id"),
//   },
// };
// if (process.env.REACT_APP_STAGE === "Build for dev") {
//   module.exports = {
//     base_url: "https://oz2b40r5u6.execute-api.ap-south-1.amazonaws.com/dev/",
//     GoogleMapsAPI: "AIzaSyCpUaXRcQq4A4OXVROcFI-orTBcZ-UR9go",
//     paymentlink: "https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=",
//     captchaKey: "6LdOtTwoAAAAAJ5DCFxjVG6Q94s3WudcU3XccBmr",
//     PUBNUB: {
//       publishKey: "pub-c-3297953a-4600-4ab4-af95-e10746c420dc",
//       subscribeKey: "sub-c-438a180a-190a-11eb-a0a1-be9072d3ef6c",
//       secretKey: "sec-c-ZDQzODA0ODEtZjdlMi00N2QzLWFjNWMtOTMzMDViZmFhYmEz",
//       uuid: localStorage.getItem("customer_id"),
//     },
//   };
// } else if (process.env.REACT_APP_STAGE === "Build for QA") {
//   module.exports = {
//     base_url: "https://b03qxj9nsi.execute-api.ap-south-1.amazonaws.com/qa/",
//     GoogleMapsAPI: "AIzaSyCpUaXRcQq4A4OXVROcFI-orTBcZ-UR9go",
//     paymentlink: "https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=",
//     captchaKey: "6LdOtTwoAAAAAJ5DCFxjVG6Q94s3WudcU3XccBmr",
//     PUBNUB: {
//       publishKey: "pub-c-886f3fff-6a0a-435e-b1d5-3fe827a577c2",
//       subscribeKey: "sub-c-8a561b02-3341-11eb-9d95-7ab25c099cb1",
//       secretKey: "sec-c-MjY1ODA2MmMtNGRhMS00ZmJiLWIzNzMtMzA5Y2IxZDQyZDgz",
//       uuid: localStorage.getItem("customer_id"),
//     },
//   };
// } else {
//   module.exports = {
//     base_url: " https://m6llxu78ng.execute-api.ap-south-1.amazonaws.com/prod/",
//     GoogleMapsAPI: "AIzaSyCpUaXRcQq4A4OXVROcFI-orTBcZ-UR9go",
//     paymentlink: "https://oppwa.com/v1/paymentWidgets.js?checkoutId=",
//     captchaKey: "6LdOtTwoAAAAAJ5DCFxjVG6Q94s3WudcU3XccBmr",
//     PUBNUB: {
//       publishKey: "pub-c-b2a2bb99-6779-43db-99ba-578df52ec5c0",
//       subscribeKey: "sub-c-37e45bc6-3342-11eb-9713-12bae088af96",
//       secretKey: "sec-c-YTA0Nzg1MDEtZTVhZC00ZTA1LWIyMDItZDAwZWYzMzAxOGQ5",
//       uuid: localStorage.getItem("customer_id"),
//     },
//   };
// }
