import React from 'react';
import ReactDOM from 'react-dom';
import { Modal } from "antd";
import { connect } from "react-redux";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import "./index.css";
const PREFIX_URL = 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/';

class Kitchencarouselcard extends React.Component {

  constructor() {
    super();
    this.state = {
      showIndex: false,
      showBullets: false,
      infinite: true,
      showThumbnails: true,
      showFullscreenButton: true,
      showGalleryFullscreenButton: true,
      showPlayButton: true,
      showGalleryPlayButton: true,
      showNav: true,
      isRTL: false,
      slideDuration: 450,
      slideInterval: 2000,
      slideOnThumbnailOver: false,
      thumbnailPosition: 'left',
      showVideo: {},
      imagesBanner:[],
      images: [],
      thumbnail_image: "",
      visibletransactions: false
    };

  
  }

  componentDidMount=async()=>{
    if(this.props.videoId && this.props.videoId !== ""){
    await this.getVideoThumbnails(this.props.videoId)
  }
        this.setState({
            imagesBanner: this.props.fetchimage != undefined && this.props.fetchimage.length ? this.props.fetchimage : []
        },()=>{
          this.setState({
            images: this.props.videoId?[
                {
                  thumbnail: this.state.thumbnail_image != undefined? this.state.thumbnail_image:"",
                  original:   this.state.thumbnail_image!= undefined? this.state.thumbnail_image:"",
                  embedUrl: 'https://player.vimeo.com/video/'+this.props.videoId,
                  renderItem: this._renderVideo.bind(this)
                }
              ].concat(this._getStaticImages())
              :
              [].concat(this._getStaticImages())
        })
        })
  }

  componentDidUpdate=async(prevProps, prevState)=>{
    // console.log(prevProps)
    // console.log(prevState)
    // if(this.props.videoId && this.props.videoId !== ""){
    //   await this.getVideoThumbnails(this.props.videoId)
    // }
    if (this.state.slideInterval !== prevState.slideInterval ||
        this.state.slideDuration !== prevState.slideDuration) {
      // refresh setInterval
      this._imageGallery.pause();
      this._imageGallery.play();
    }
    if(this.state.thumbnail_image != prevState.thumbnail_image || prevProps.designId !== this.props.designId || prevProps.newdesignId != this.props.newdesignId || prevProps.fetchimage != this.props.fetchimage){
      await this.getVideoThumbnails(this.props.videoId)
      this.setState({
        imagesBanner: this.props.fetchimage != undefined && this.props.fetchimage.length ? this.props.fetchimage : []
    },()=>{
      this.setState({
        images: this.props.videoId?[
            {
              thumbnail: this.state.thumbnail_image != undefined? this.state.thumbnail_image:"",
              original:   this.state.thumbnail_image!= undefined? this.state.thumbnail_image:"",
              embedUrl: 'https://player.vimeo.com/video/'+this.props.videoId,
              renderItem: this._renderVideo.bind(this)
            }
          ].concat(this._getStaticImages())
          :
          [].concat(this._getStaticImages())
        })
      })
  }
  }
  _onImageClick(event) {
    console.debug('clicked on image', event.target, 'at index', this._imageGallery.getCurrentIndex());
  }

  _onImageLoad(event) {
    console.debug('loaded image', event.target.src);
  }

  _onSlide(index) {
    this._resetVideo();
    console.debug('slid to index', index);
  }

  _onPause(index) {
    console.debug('paused on index', index);
  }

  _onScreenChange(fullScreenElement) {
    console.debug('isFullScreen?', !!fullScreenElement);
  }

  _onPlay(index) {
    console.debug('playing from index', index);
  }

  _handleInputChange(state, event) {
    this.setState({[state]: event.target.value});
  }

  _handleCheckboxChange(state, event) {
    this.setState({[state]: event.target.checked});
  }

  _handleThumbnailPositionChange(event) {
    this.setState({thumbnailPosition: event.target.value});
  }


  _getStaticImages() {
    var fetchimagearray = this.state.imagesBanner != undefined && this.state.imagesBanner.length > 0 ?this.state.imagesBanner.map((e,i)=>{
      if(this.props.orderType && this.props.orderType == "appliance"){
        return {
          original: e,
          thumbnail: e
        }
      } else {
        return {
          original: e.image_url,
          thumbnail: e.image_url
        }
      }
      }):[]
    return fetchimagearray;
  }

  _resetVideo() {
    this.setState({showVideo: {}});

    if (this.state.showPlayButton) {
      this.setState({showGalleryPlayButton: true});
    }

    if (this.state.showFullscreenButton) {
      this.setState({showGalleryFullscreenButton: true});
    }
  }

  _toggleShowVideo(url) {
    this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
    this.setState({
      showVideo: this.state.showVideo
    });

    if (this.state.showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({showGalleryPlayButton: false});
      }

      if (this.state.showFullscreenButton) {
        this.setState({showGalleryFullscreenButton: false});
      }
    }
  }

  _renderVideo(item) {
    return (
      <div>
        {
          this.props.videoId && this.props.fromPage=="kitchenDetail" ?
          <div className='video-wrapper'>
              <a
                className='close-video'
                onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
              >
              </a>
              <iframe
                width='560'
                height='500'
                src={item.embedUrl+"?autoplay=1&muted=1"}
                frameBorder='0'
                allowFullScreen
                className="test"
              >
              </iframe>
          </div>
            :
            this.state.showVideo[item.embedUrl] ?
            <div className='video-wrapper'>
                <a
                  className='close-video'
                  onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
                >
                </a>
                <iframe
                  width='560'
                  height='500'
                  src={item.embedUrl}
                  frameBorder='0'
                  allowFullScreen
                  className="test"
                >
                </iframe>
            </div>
          :
            <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
              <div className='play-button'></div>
              <img className='image-gallery-image' src={item.original} />
              {
                item.description &&
                  <span
                    className='image-gallery-description'
                    style={{right: '0', left: 'initial'}}
                  >
                    {item.description}
                  </span>
              }
            </a>
        }
      </div>
    );
  }

   getVideoThumbnails=async(videoid)=>{
     fetch(`https://vimeo.com/api/v2/video/${videoid}.json`)
    .then(response => {
      return response.text();
    })
    .then(data => {
      const { thumbnail_large } = JSON.parse(data)[0];
       this.setState({
        thumbnail_image: thumbnail_large
      })
      return
    })
    .catch(error => {
      console.log(error);
    });
  }

  render() { 
    console.log("props in kitchen carousel", this.props, "imagesBanner :",this.state.imagesBanner)

    return (
    // this.state.imagesBanner.length > 0 || this.props.videoId ?
    this.props.details? <Modal
                    backdrop={"static"}
                    footer={null}
                    // visible={props.visibletransactions}
                    visible={this.props.visibletransactions}
                    className={this.props.details && this.props.details.Languages == "en"?"mopitstoptransactions":"mopitstoptransactionsarabic"}
                    onCancel={this.props.handleCancelone}
                    maskClosable={false}
                >
        <ImageGallery
        ref={i => this._imageGallery = i}
        items={this.state.images}
        lazyLoad={false}
        onClick={this._onImageClick.bind(this)}
        onImageLoad={this._onImageLoad}
        onSlide={this._onSlide.bind(this)}
        onPause={this._onPause.bind(this)}
        onScreenChange={this._onScreenChange.bind(this)}
        onPlay={this._onPlay.bind(this)}
        infinite={this.state.infinite}
        showBullets={this.state.showBullets}
        showFullscreenButton={this.state.showFullscreenButton && this.state.showGalleryFullscreenButton}
        showPlayButton={this.props.fromPage ? this.state.showPlayButton && this.state.showGalleryPlayButton: false}
        showThumbnails={this.state.showThumbnails}
        showIndex={this.state.showIndex}
        showNav={this.props.fromPage ? this.state.showNav: false}
        isRTL={this.state.isRTL}
        thumbnailPosition={this.props.fromPage ? "left": "bottom"}
        slideDuration={parseInt(this.state.slideDuration)}
        slideInterval={parseInt(this.state.slideInterval)}
        slideOnThumbnailOver={this.state.slideOnThumbnailOver}
        additionalClass="app-image-gallery"
        />
        </Modal>

        : 
        <ImageGallery
        ref={i => this._imageGallery = i}
        items={this.state.images}
        lazyLoad={false}
        onClick={this._onImageClick.bind(this)}
        onImageLoad={this._onImageLoad}
        onSlide={this._onSlide.bind(this)}
        onPause={this._onPause.bind(this)}
        onScreenChange={this._onScreenChange.bind(this)}
        onPlay={this._onPlay.bind(this)}
        infinite={this.state.infinite}
        showBullets={this.state.showBullets}
        showFullscreenButton={this.state.showFullscreenButton && this.state.showGalleryFullscreenButton}
        showPlayButton={this.props.fromPage ? this.state.showPlayButton && this.state.showGalleryPlayButton: false}
        showThumbnails={this.state.showThumbnails}
        showIndex={this.state.showIndex}
        showNav={this.props.fromPage ? this.state.showNav: false}
        isRTL={this.state.isRTL}
        thumbnailPosition={this.props.fromPage ? "left": "bottom"}
        slideDuration={parseInt(this.state.slideDuration)}
        slideInterval={parseInt(this.state.slideInterval)}
        slideOnThumbnailOver={this.state.slideOnThumbnailOver}
        additionalClass="app-image-gallery"
        />
        // :""
      
    );
  }
}


export default Kitchencarouselcard