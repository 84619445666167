let InitialStateValue = {
    meetDateAndTime : {}
}

const bookDemoMeetDateAndTimeReducer = (state = InitialStateValue, action) => {
    switch (action.type) {
     
      case 'MEET_DATE_TIME':
        return {
            meetDateAndTime : {date:action.data.date, time:action.data.time}
        }
      default:
        return state;
    } 
  };

export default bookDemoMeetDateAndTimeReducer