import { combineReducers } from "redux";
import Languages from "./languageSwitchReducer";
import designKitchen from "./designKitchenReducer";
import chooseStyle from "./chooseStyleReducer";
import moreInformation from "./bookDemoMoreInformationReducer"
import meetDateAndTime from "./bookDemoMeetDateAndTimeReducer"
import login from "./login"
import kitchenlisting from "./kitchenlisting"
import bookDemoReducer from "./bookDemoReducer"
import orderdetails from "./orderdetail"
import applianceorderdetails from "./applianceOrderDetail"
import nonKitchen from "./nonKitchenReducer";

const rootReducer = combineReducers({
    Languages: Languages,
    login,
    orderdetails,
    kitchenlisting,
    designKitchen: designKitchen,
    chooseStyle: chooseStyle,
    moreInformation:moreInformation,
    meetDateAndTime: meetDateAndTime,
    bookADemoValue: bookDemoReducer,
    applianceorderdetails,
    nonKitchen: nonKitchen
})

export default rootReducer

