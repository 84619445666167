import React from "react";

const CountDown = ({
  CountDownStart = 0,
  className = "",
  setCounterStoped,
}) => {
  const [counter, setCounter] = React.useState(CountDownStart);
  React.useEffect(() => {
    if (counter === 0) {
      return setCounterStoped(true);
    }
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter, setCounterStoped]);

  return <span className={`inline-block w-7 ${className}`}>{counter}</span>;
};

export default CountDown;
