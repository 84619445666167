import React from 'react';
import { useSelector } from "react-redux";
import Translation from "../../language/translate"
import "./index.css"

const LetsBegin=(props)=>{
    const details = useSelector(state => state);
return (
<React.Fragment>
    <div className="lets-get-started-wrap">
        <div className="lets-get-started">
            <div className="header-title">
            {Translation[details.Languages]["LetsGetItStarted"]} 
            </div>
            <div className="header-description">
            {Translation[details.Languages]["LetsGetItStartedDescription"]} 
            </div>
        </div>

    </div>
</React.Fragment>
);
}

export default LetsBegin;