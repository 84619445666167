/* eslint-disable */

const initialState = {
    showLoader: false,
    kitchendetails:[],
    kitchendetailscount:[],
    categorydetails:[]
  };
  
  const kitchenlisting = (state = initialState, action) => {
    console.log(action, "actions");
    switch (action.type) {
      
        case "KITCHEN_SUCCESS":
        return {...state,kitchendetails:action.success.data.list,kitchendetailscount:action.success.data.total_count,
          categorydetails:action.success.data.category_details,showLoader: false};

        case "KITCHEN_REQUESTING":
        return {...state,kitchendetails:[],showLoader: true};

        case "KITCHEN__FAILURE":
        return {...state,kitchendetails:[],showLoader: false};

      default:
        return state;
    }
  };
  
  export default kitchenlisting;
  