let InitialStateValue = {
    nonkitchen : {}
}

const nonKitchenReducer = (state = InitialStateValue, action) => {
    switch (action.type) {
     
      case 'NON_KITCHEN_DATA':
        return {
            nonkitchen : {service:action.data.service, serviceArabic: action.data.serviceArabic, serviceId: action.data.serviceId, kitchenOrder: action.data.kitchenOrder, serviceCode: action.data.serviceCode}
        }
      default:
        return state;
    }
  };

  
export default nonKitchenReducer