import React, {useState, useLayoutEffect, useEffect} from 'react';
import {useSelector } from "react-redux";
import { Drawer } from 'antd';
import Notification from "../notification";
import OrderListing from "../order-listing";
import Chatnotifications from "../chatnotifications";
import Locationheader from "../locationheader";
import Translation from "../../language/translate"
import "./index.css"
import Meetdesignerswithslot from '../meetdesigners-withslot';

const DrawerComponent=(props)=>{
  const [meetdesignerModal, setmeetdesignerModal]=useState(false)
  const [notificationLengthState, setNotificationLengthState] = useState("")
  const [chatnotificationLengthState, setchatNotificationLengthState] = useState("")
  const [notifnvalue,setnotifnvalue] = useState("")
  const [readstatus,setreadstatus] = useState(false)
  const [notificationId,setnotificationid] = useState("")
  const details = useSelector(state => state);
  const [orderId, setOrderId]=useState("")
  const closemeetdesigner =()=> 
  {
    setOrderId("")
    setmeetdesignerModal(false)
  }


  useLayoutEffect(() => {
    if (props.drawerOpen === true) {
      document.body.style.position = 'fixed';
    }
    else{
        document.body.style.position = 'unset';
    }
  }, [props.drawerOpen]);

const setmeetdesignerModalPopUp =(id,notificationid,isreadstatus,value)=>{
  console.log(value)
  console.log(notificationid)
  console.log(id)
  setnotificationid(notificationid)
  setnotifnvalue(value)
  setmeetdesignerModal(true)
  setreadstatus(isreadstatus)
  setOrderId(id)
} 

const notificationLength =(length)=>{
  setNotificationLengthState(length)
}

const chatnotificationLength =(length)=>{
  console.log(length)
  setchatNotificationLengthState(length) 
}

return (
<React.Fragment> 

  <Drawer width={600} title={props.drawerPage === "notification" ? Translation[details.Languages]["Notsmall"] + " " + "(" +notificationLengthState +")" : props.drawerPage === "chatnotifications" ? Translation[details.Languages]["Chatnotifications"] + " " + "(" +chatnotificationLengthState +")" : props.drawerPage === "viewOrders" ? Translation[details.Languages]["MyOrders"]:Translation[details.Languages]["SelectLocation"]} placement={"right"} closable={true}
    onClose={props.closedrawer("")} className={details.Languages == "en"? "drawer-class":"drawer-classarabic"} visible={props.drawerOpen}>
    <div className="drawer-class-wrap">
        {props.drawerPage === "notification" ?
          <Notification notificationLength = {notificationLength} setmeetdesignerModalPopUp={setmeetdesignerModalPopUp} closemeetdesignerModal={props.closedrawer("")} />
        :props.drawerPage === "viewOrders"?
          <OrderListing customeroderlist={props.customeroderlist} closedrawer={props.closedrawer("")} showLoader={props.showLoader}/>
        :props.drawerPage === "viewLocation"?
        <Locationheader customeroderlist={props.customeroderlist} closedrawer={props.closedrawer("")} />
        :props.drawerPage === "chatnotifications"?
        <Chatnotifications chatnotificationLength = {chatnotificationLength} setmeetdesignerModalPopUp={setmeetdesignerModalPopUp} closemeetdesignerModal={props.closedrawer("")}/>
        :""
        }
    </div>
    <Meetdesignerswithslot  orderId = {orderId !== ""? orderId : ""} notifnvalue={notifnvalue}  meetdesignerModal={meetdesignerModal} closedrawer={props.closedrawer("")} closemeetdesignerModal={closemeetdesigner} notificationId={notificationId} readstatus={readstatus} />
  </Drawer>
</React.Fragment>
);
}

export default DrawerComponent;