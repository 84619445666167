import React from 'react';
import Router from './Router';
import { BrowserRouter, Switch, Route,Redirect} from "react-router-dom"
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css"

function App() {

  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
    
  )
}

export default App;
