import React, { useState } from 'react';
import { Card, Tab, Tabs, Button, ButtonGroup, Row, Col } from 'react-bootstrap'
import OrderDetail from "../../images/orderdetailtwo.png"
import { useSelector} from "react-redux";
import moment from "moment";
import {Modal} from "antd";
import Translation from "../../language/translate"
import { useHistory } from "react-router";
import { ClipLoader } from "react-spinners";
import "./index.css"
import BookFreeConsultation from '../../sections/book-free-consultation';

const OrderListing=(props)=>{

  const history = useHistory();
  const details = useSelector(state => state);
  const [key, setKey] = useState('Kitchen');
  const [show, setShow] = useState(false);
  const [bookModalOpen, openBookModalpopup] = useState(false);
  const [bookRepeatOrder, openBookRepeatOrder] = useState(false);
  const [orderData, setOrderDetailsData] = useState('')
  const [repeatData, setRepeatData] = useState('')

 const orderdetailsview=(oderlistid)=>{
      console.log(oderlistid)
      window.scroll(0,0)
    if(oderlistid != undefined){
      props.closedrawer()
    history.push({
      pathname:  "/orderdetails/"+oderlistid,
    })
      }
  }

  const appliancesdetailsview=(oderlistid)=>{
    console.log(oderlistid)
    window.scroll(0,0)
  if(oderlistid != undefined){
    props.closedrawer()
  history.push({
    pathname:  "/appliancedetails/"+oderlistid,
  })
    }
}

  const handleTab = (value) => {
    setKey(value)
  }

  const handleClose = () => {
    setShow(false);
  }
  const handleShow = (alllist) => {
    setShow(false);
    // console.log("allList :", alllist)
    // if(alllist && alllist.repeat_order !== {}){
    //   const data = alllist
    //   setOrderDetailsData(data.repeat_order)
    // }
    // setShow(true);
    // openBookRepeatOrder(true);

    if(localStorage.getItem('user_registered') == false || localStorage.getItem('user_registered') == undefined ){
      history.push({
        pathname:  "/login",
      })
    } else {
        openBookModalpopup(true)
        // setShow(false);
        if(alllist ){
          const data = alllist
          setOrderDetailsData(data.repeat_order)
        }
        // setShow(true);
        openBookRepeatOrder(true);
    }
  }

  const handleRepeat = (data) => {
    console.log("handle repeat on click :", data)
    setRepeatData(data)
    setShow(true);
  }

  const closeBookModal = () => {
    openBookRepeatOrder(false)
    openBookModalpopup(false)
  }


  console.log("selected :", key)
  console.log("alllist data in the order-listing :", props.customeroderlist)
return (
   
<React.Fragment>
  
  <div> 
  {/* <ButtonGroup className= "col-lg-12 col-md-12" style={{display: "flex", backgroundColor: 'rgb(141 145 153)', borderRadius: '3px'}}>
      <Button style={{ backgroundColor: 'rgb(58 63 75)', marginLeft: 'auto', borderRadius: '3px', color: '#fff', border: 'none',margin: '5px'}} onClick={() => handleTab('Kitchen')}>Kitchen Sets</Button>
      <Button style={{ backgroundColor: 'rgb(58 63 75)', marginRight: 'auto', borderRadius: '3px', color: '#fff', border: 'none', margin: '5px'}}onClick={() => handleTab('Others')}>Other Orders</Button>
    </ButtonGroup> */}

<ButtonGroup className= "col-lg-12 col-md-12" style={{display: "flex", borderRadius: '3px', height: '57px'}}>
      {key == "Kitchen"?
      <>
      <Button variant="outline-light" style={{marginLeft: 'auto', borderRadius: '3px', color: '#fff', border: '1px solid #fff', margin:'5px',backgroundColor: 'rgb(35 41 66)'}} onClick={() => handleTab('Kitchen')}>{Translation[details.Languages]["KitchenOrder"]}</Button>
      <br />
      <Button variant="outline-light" style={{marginLeft: 'auto', borderRadius: '3px', color: '#000', border: '1px solid #000', margin:'5px',backgroundColor: '#fff'}} onClick={() => handleTab('Others')}>{Translation[details.Languages]["OtherOrder"]}</Button>
      </>
      :
      <>
      <Button variant="outline-light" style={{marginLeft: 'auto', borderRadius: '3px', color: '#000', border: '1px solid #000', margin:'5px',backgroundColor: '#fff'}} onClick={() => handleTab('Kitchen')}>{Translation[details.Languages]["KitchenOrder"]}</Button>
      <br />
      <Button variant="outline-light" style={{marginLeft: 'auto', borderRadius: '3px', color: '#fff', border: '1px solid #fff', margin:'5px',backgroundColor: 'rgb(35 41 66)'}} onClick={() => handleTab('Others')}>{Translation[details.Languages]["OtherOrder"]}</Button>
      </>
  }
    </ButtonGroup>
    
        {
          props.showLoader ?
          <div className="loader_center">
								{" "}
          <ClipLoader color={"#242E49"} loading={props.showLoader} />{" "}</div>:
          props.customeroderlist.length>0?
          props.customeroderlist.map((alllist)=>{
            // console.log("alllist :", alllist, alllist.order_type == "kitchen")
    return (
<div>

    {key == 'Kitchen' ?  
    <div>
      {(alllist.order_type === "kitchen" || alllist.order_type === "kitchen_replacements")?
      <div>
      <div className="row notification-tile" onClick={()=>orderdetailsview(alllist._id)}>
        <div className="col-lg-2 col-md-3 orders_center" style={{margin: "auto"}}>
          <img src={OrderDetail} alt="profile" className="order_resp_fix" />
        </div>
        <div className="col-lg-7 col-md-6 orders_center">
          <p className="notf-order-id" style={{lineHeight: "30px"}}>
            {alllist.order_id}
          </p>
          <p className="notf-contract-status" style={{color: "#F1823B"}}>
              {
              alllist.order_checklist_status == "booked_a_demo"?<p className="fetchstatus disignmilestonecolor" title={Translation[details.Languages]["Bookedademo"]}>{Translation[details.Languages]["Bookedademo"]}</p>:
              alllist.order_checklist_status == "designer_accepted_and_assigned"?<p className="fetchstatus disignmilestonecolor" title={Translation[details.Languages]["Designerassigned"]}>{Translation[details.Languages]["Designerassigned"]}</p>:
              alllist.order_checklist_status == "out_for_customer_meeting"?<p className="fetchstatus disignmilestonecolor" title={Translation[details.Languages]["DesigneroutforMeeting"]}>{Translation[details.Languages]["DesigneroutforMeeting"]}</p>:
              alllist.order_checklist_status == "designer_meeting_with_customer"?<p className="fetchstatus disignmilestonecolor" title={Translation[details.Languages]["Meetingscheduled"]}>{Translation[details.Languages]["Meetingscheduled"]}</p>:
              alllist.order_checklist_status == "on_going_meeting"?<p className="fetchstatus disignmilestonecolor" title={Translation[details.Languages]["MeetinginProgress"]}>{Translation[details.Languages]["MeetinginProgress"]}</p>:
              alllist.order_checklist_status == "meeting_completed"?<p className="fetchstatus disignmilestonecolor" title={Translation[details.Languages]["MeetingCompleted"]}>{Translation[details.Languages]["MeetingCompleted"]}</p>:
              alllist.order_checklist_status == "initial_draft_uploaded"?<p className="fetchstatus disignmilestonecolor" title={Translation[details.Languages]["Initialdraft"]}>{Translation[details.Languages]["Initialdraft"]}</p>:
              alllist.order_checklist_status == "revised_draft_uploaded"?<p className="fetchstatus disignmilestonecolor" title={Translation[details.Languages]["Reviseddraft"]}>{Translation[details.Languages]["Reviseddraft"]}</p>:
              alllist.order_checklist_status == "customer_rejected_design"?<p className="fetchstatus disignmilestonecolor" title={Translation[details.Languages]["DraftRejected"]}>{Translation[details.Languages]["DraftRejected"]}</p>:
              alllist.order_checklist_status == "customer_approved_design"?<p className="fetchstatus disignmilestonecolor" title={Translation[details.Languages]["DraftApproved"]}>{Translation[details.Languages]["DraftApproved"]}</p>:
              alllist.order_checklist_status == "final_draft_uploaded" || 
							alllist.order_checklist_status == "request_for_payment_change" ||
						  alllist.order_checklist_status == "payment_change_approved"||
							alllist.order_checklist_status == "payment_change_rejected" ||
							alllist.order_checklist_status == "contract_document_generated"?<p className="fetchstatus disignmilestonecolor" title={Translation[details.Languages]["Finaldraftuploaded"]}>{Translation[details.Languages]["Finaldraftuploaded"]}</p>:
              alllist.order_checklist_status == "contract_document_uploaded"?<p className="fetchstatus disignmilestonecolor" title={Translation[details.Languages]["ContractUploaded"]}>{Translation[details.Languages]["ContractUploaded"]}</p>:
              alllist.order_checklist_status == "contract_document_signed"?<p className="fetchstatus disignmilestonecolor" title={Translation[details.Languages]["Contractsigned"]}>{Translation[details.Languages]["Contractsigned"]}</p>:
              alllist.order_checklist_status == "partial_payment_done"?<p className="fetchstatus disignmilestonecolor" title={Translation[details.Languages]["Partialpaymentdone"]}>{Translation[details.Languages]["Partialpaymentdone"]}</p>:
              alllist.order_checklist_status == "advance_payment_done"?<p className="fetchstatus disignmilestonecolor" title={Translation[details.Languages]["Advancepaymentdone"]}>{Translation[details.Languages]["Advancepaymentdone"]}</p>:
              alllist.order_checklist_status == "in_production"?<p className="fetchstatus productionmilestonecolor" title={Translation[details.Languages]["Inproduction"]}>{Translation[details.Languages]["Inproduction"]}</p>:
              alllist.order_checklist_status == "items_reached_warehouse"?<p className="fetchstatus productionmilestonecolor" title={Translation[details.Languages]["Itemsreachedwarehouse"]}>{Translation[details.Languages]["Itemsreachedwarehouse"]}</p>:
              alllist.order_checklist_status == "final_payment_done"?<p className="fetchstatus productionmilestonecolor" title={Translation[details.Languages]["Finalpaymentdone"]}>{Translation[details.Languages]["Finalpaymentdone"]}</p>:
              alllist.order_checklist_status == "request_for_delivery_appointment"?<p className="fetchstatus productionmilestonecolor" title={Translation[details.Languages]["DeliveryAppointmnet"]}>{Translation[details.Languages]["DeliveryAppointmnet"]}</p>:
              alllist.order_checklist_status == "delivery_person_assigned"?<p className="fetchstatus productionmilestonecolor" title={Translation[details.Languages]["Deliverypersonassigned"]}>{Translation[details.Languages]["Deliverypersonassigned"]}</p>:
              alllist.order_checklist_status == "reached_warehouse"?<p className="fetchstatus productionmilestonecolor" tile={Translation[details.Languages]["Reachedwarehouse"]}>{Translation[details.Languages]["Reachedwarehouse"]}</p>:
              alllist.order_checklist_status == "items_verified"?<p className="fetchstatus productionmilestonecolor" title={Translation[details.Languages]["Itemsverified"]}>{Translation[details.Languages]["Itemsverified"]}</p>:
              alllist.order_checklist_status == "out_for_delivery"?<p className="fetchstatus productionmilestonecolor" title={Translation[details.Languages]["Outfordelivery"]}>{Translation[details.Languages]["Outfordelivery"]}</p>:
              alllist.order_checklist_status == "delivery_person_reached_customer_location"?<p className="fetchstatus productionmilestonecolor" title={Translation[details.Languages]["Reachedlocation"]}>{Translation[details.Languages]["Reachedlocation"]}</p>:
              alllist.order_checklist_status == "confirm_item_delivery"?<p className="fetchstatus productionmilestonecolor" title={Translation[details.Languages]["confirmDelivery"]}>{Translation[details.Languages]["confirmDelivery"]}</p>:
              alllist.order_checklist_status == "partially_delivered"?<p className="fetchstatus productionmilestonecolor" title={Translation[details.Languages]["PartiallyDelivered"]}>{Translation[details.Languages]["PartiallyDelivered"]}</p>:
              alllist.order_checklist_status == "items_delivered"?<p className="fetchstatus productionmilestonecolor" title={Translation[details.Languages]["Itemsdelivered"]}>{Translation[details.Languages]["Itemsdelivered"]}</p>:
              alllist.order_checklist_status == "fitter_assigned"?<p className="fetchstatus fittingmilestone" title={Translation[details.Languages]["Fitterassigned"]}>{Translation[details.Languages]["Fitterassigned"]}</p>:
              alllist.order_checklist_status == "out_for_fitting_work"?<p className="fetchstatus fittingmilestone" title={Translation[details.Languages]["FittingWork"]}>{Translation[details.Languages]["FittingWork"]}</p>:
              alllist.order_checklist_status == "fitter_reached_customer_location"?<p className="fetchstatus fittingmilestone" title={Translation[details.Languages]["FitterReached"]}>{Translation[details.Languages]["FitterReached"]}</p>:
              alllist.order_checklist_status == "work_in_progress"?<p className="fetchstatus fittingmilestone" title={Translation[details.Languages]["WorkinProgress"]}>{Translation[details.Languages]["WorkinProgress"]}</p>:
              alllist.order_checklist_status == "work_paused"?<p className="fetchstatus fittingmilestone" title={Translation[details.Languages]["Workpause"]}>{Translation[details.Languages]["Workpause"]}</p>:
              alllist.order_checklist_status == "kitchen_ready"?<p className="fetchstatus kitchenreadymilestone" title={Translation[details.Languages]["Kitchenready"]}>{Translation[details.Languages]["Kitchenready"]}</p>:
              ""
              }
          </p>
        </div>
        <div className="col-lg-3 col-md-3 notf-order-id orders_center">
          {moment(alllist.order_booked_date).format("MMM Do")}
        </div>
      </div>
        {
        (Object.keys(alllist.repeat_order).length == 0 ) 
        ? 
          <span style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end', borderBottom: '1px solid rgb(219 210 210)', paddingBottom: '10px'}}> </span> 
        :
          <>
          {
            (alllist.creation_platform === "online" && alllist.order_type !== "kitchen_replacements" ) ?
            <>
            <div className="col-lg-12 col-md-12 notf-order-id orders_center" style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end', cursor: 'pointer', borderBottom: '1px solid rgb(219 210 210)', paddingBottom: '10px'}} onClick={() => handleRepeat(alllist)}>
              <b><u>{Translation[details.Languages]["RepeatOrder"]}</u></b>
            </div>
            </>
            :
            <>
            <div className="col-lg-12 col-md-12 notf-order-id orders_center" style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end', cursor: 'pointer', borderBottom: '1px solid rgb(219 210 210)', paddingBottom: '10px'}}>
              
            </div>
            </>
            
          }
          </>
        }
      </div>
      : ""}
    </div>
    : 
    <div>
      {(alllist.order_type === "appliances" || alllist.order_type === "worktops" || alllist.order_type === "services" || alllist.order_type === "Maintenance"|| alllist.order_type === "Spareparts" ) ? 
      <div className="row notification-tile" onClick={()=>appliancesdetailsview(alllist._id
        )}>
          {/* parent_order_id */}
        <div className="col-lg-2 col-md-3 orders_center" style={{margin: "auto"}}>
          <img src={OrderDetail} alt="profile" className="order_resp_fix" />
        </div>
        <div className="col-lg-7 col-md-6 orders_center">
          <p className="notf-order-id" style={{lineHeight: "30px"}}>
            {alllist.order_id} <span className='text-capitalize'>({alllist.order_type})</span>
          </p>
          <p className="notf-contract-status" style={{color: "#F1823B"}}>
              {
              alllist.order_checklist_status =="appointment_booked"?<p className="fetchstatus disignmilestonecolor" title={Translation[details.Languages]["Bookedademo"]}>
                {/* {Translation[details.Languages]["Bookedademo"]} */}
                Appointment Booked
                </p>:
              alllist.order_checklist_status == "designer_accepted_and_assigned"?<p className="fetchstatus disignmilestonecolor" title={Translation[details.Languages]["Designerassigned"]}>{Translation[details.Languages]["Designerassigned"]}</p>:
              alllist.order_checklist_status == "out_for_customer_meeting"?<p className="fetchstatus disignmilestonecolor" title={Translation[details.Languages]["DesigneroutforMeeting"]}>{Translation[details.Languages]["DesigneroutforMeeting"]}</p>:
              alllist.order_checklist_status == "designer_meeting_with_customer"?<p className="fetchstatus disignmilestonecolor" title={Translation[details.Languages]["Meetingscheduled"]}>{Translation[details.Languages]["Meetingscheduled"]}</p>:
              alllist.order_checklist_status == "on_going_meeting"?<p className="fetchstatus disignmilestonecolor" title={Translation[details.Languages]["MeetinginProgress"]}>{Translation[details.Languages]["MeetinginProgress"]}</p>:
              alllist.order_checklist_status == "meeting_completed"?<p className="fetchstatus disignmilestonecolor" title={Translation[details.Languages]["MeetingCompleted"]}>{Translation[details.Languages]["MeetingCompleted"]}</p>:
              alllist.order_checklist_status == "initial_offer_uploaded"?<p className="fetchstatus disignmilestonecolor" title={Translation[details.Languages]["Initialdraft"]}>
                {/* {Translation[details.Languages]["Initialdraft"]} */}
                Initial Offer Uploaded
                </p>:
              alllist.order_checklist_status == "revised_offer_uploaded"?<p className="fetchstatus disignmilestonecolor" title={Translation[details.Languages]["Reviseddraft"]}>
                {/* {Translation[details.Languages]["Reviseddraft"]} */}
                Revised Offer Uploaded
                </p>:
              alllist.order_checklist_status == "customer_rejected_offer"?<p className="fetchstatus disignmilestonecolor" title={Translation[details.Languages]["DraftRejected"]}>
                {/* {Translation[details.Languages]["DraftRejected"]} */}
                Customer Rejected Offer
                </p>:
              alllist.order_checklist_status == "customer_approved_offer"?<p className="fetchstatus disignmilestonecolor" title={Translation[details.Languages]["DraftApproved"]}>
                {/* {Translation[details.Languages]["DraftApproved"]} */}
                Customer Approved Offer
                </p>:
              alllist.order_checklist_status == "final_offer_uploaded" || 
							alllist.order_checklist_status == "request_for_payment_change" ||
						  alllist.order_checklist_status == "payment_change_approved"||
							alllist.order_checklist_status == "payment_change_rejected" ||
							alllist.order_checklist_status == "contract_document_generated"?<p className="fetchstatus disignmilestonecolor" title={Translation[details.Languages]["Finaldraftuploaded"]}>
                {/* {Translation[details.Languages]["Finaldraftuploaded"]} */}
                Final Offer Uploaded
                </p>:
              alllist.order_checklist_status == "contract_document_uploaded"?<p className="fetchstatus disignmilestonecolor" title={Translation[details.Languages]["ContractUploaded"]}>{Translation[details.Languages]["ContractUploaded"]}</p>:
              alllist.order_checklist_status == "contract_document_signed"?<p className="fetchstatus disignmilestonecolor" title={Translation[details.Languages]["Contractsigned"]}>{Translation[details.Languages]["Contractsigned"]}</p>:
              alllist.order_checklist_status == "partial_payment_done"?<p className="fetchstatus disignmilestonecolor" title={Translation[details.Languages]["Partialpaymentdone"]}>{Translation[details.Languages]["Partialpaymentdone"]}</p>:
              alllist.order_checklist_status == "advance_payment_done"?<p className="fetchstatus disignmilestonecolor" title={Translation[details.Languages]["Advancepaymentdone"]}>{Translation[details.Languages]["Advancepaymentdone"]}</p>:
              alllist.order_checklist_status == "in_production"?<p className="fetchstatus productionmilestonecolor" title={Translation[details.Languages]["Inproduction"]}>{Translation[details.Languages]["Inproduction"]}</p>:
              alllist.order_checklist_status == "items_reached_warehouse"?<p className="fetchstatus productionmilestonecolor" title={Translation[details.Languages]["Itemsreachedwarehouse"]}>{Translation[details.Languages]["Itemsreachedwarehouse"]}</p>:
              alllist.order_checklist_status == "final_payment_done"?<p className="fetchstatus productionmilestonecolor" title={Translation[details.Languages]["Finalpaymentdone"]}>{Translation[details.Languages]["Finalpaymentdone"]}</p>:
              alllist.order_checklist_status == "request_for_delivery_appointment"?<p className="fetchstatus productionmilestonecolor" title={Translation[details.Languages]["DeliveryAppointmnet"]}>{Translation[details.Languages]["DeliveryAppointmnet"]}</p>:
              alllist.order_checklist_status == "delivery_person_assigned"?<p className="fetchstatus productionmilestonecolor" title={Translation[details.Languages]["Deliverypersonassigned"]}>{Translation[details.Languages]["Deliverypersonassigned"]}</p>:
              alllist.order_checklist_status == "reached_warehouse"?<p className="fetchstatus productionmilestonecolor" tile={Translation[details.Languages]["Reachedwarehouse"]}>{Translation[details.Languages]["Reachedwarehouse"]}</p>:
              alllist.order_checklist_status == "items_verified"?<p className="fetchstatus productionmilestonecolor" title={Translation[details.Languages]["Itemsverified"]}>{Translation[details.Languages]["Itemsverified"]}</p>:
              alllist.order_checklist_status == "out_for_delivery"?<p className="fetchstatus productionmilestonecolor" title={Translation[details.Languages]["Outfordelivery"]}>{Translation[details.Languages]["Outfordelivery"]}</p>:
              alllist.order_checklist_status == "delivery_person_reached_customer_location"?<p className="fetchstatus productionmilestonecolor" title={Translation[details.Languages]["Reachedlocation"]}>{Translation[details.Languages]["Reachedlocation"]}</p>:
              alllist.order_checklist_status == "confirm_item_delivery"?<p className="fetchstatus productionmilestonecolor" title={Translation[details.Languages]["confirmDelivery"]}>{Translation[details.Languages]["confirmDelivery"]}</p>:
              alllist.order_checklist_status == "partially_delivered"?<p className="fetchstatus productionmilestonecolor" title={Translation[details.Languages]["PartiallyDelivered"]}>{Translation[details.Languages]["PartiallyDelivered"]}</p>:
              alllist.order_checklist_status == "items_delivered"?<p className="fetchstatus productionmilestonecolor" title={Translation[details.Languages]["Itemsdelivered"]}>{Translation[details.Languages]["Itemsdelivered"]}</p>:
              alllist.order_checklist_status == "fitter_assigned"?<p className="fetchstatus fittingmilestone" title={Translation[details.Languages]["Fitterassigned"]}>{Translation[details.Languages]["Fitterassigned"]}</p>:
              alllist.order_checklist_status == "out_for_fitting_work"?<p className="fetchstatus fittingmilestone" title={Translation[details.Languages]["FittingWork"]}>{Translation[details.Languages]["FittingWork"]}</p>:
              alllist.order_checklist_status == "fitter_reached_customer_location"?<p className="fetchstatus fittingmilestone" title={Translation[details.Languages]["FitterReached"]}>{Translation[details.Languages]["FitterReached"]}</p>:
              alllist.order_checklist_status == "work_in_progress"?<p className="fetchstatus fittingmilestone" title={Translation[details.Languages]["WorkinProgress"]}>{Translation[details.Languages]["WorkinProgress"]}</p>:
              alllist.order_checklist_status == "work_paused"?<p className="fetchstatus fittingmilestone" title={Translation[details.Languages]["Workpause"]}>{Translation[details.Languages]["Workpause"]}</p>:
              alllist.order_checklist_status == "goods_ready"?<p className="fetchstatus kitchenreadymilestone" title={Translation[details.Languages]["Kitchenready"]}>
                {/* {Translation[details.Languages]["Kitchenready"]} */}
                Goods Ready
                </p>: alllist.order_checklist_status == "booked_a_appliances"? "booked an appliance":
              ""
              }
          </p>
        </div>
        <div className="col-lg-3 col-md-3 notf-order-id orders_center">
          {moment(alllist.order_booked_date).format("MMM Do")}
        </div>
        <span style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end', borderBottom: '1px solid rgb(219 210 210)', paddingBottom: '10px', width: '100%'}}></span>
      </div>
       : ""}
        </div> 
        
        }

      </div>
      )
        })
        :<p className="no_items">{Translation[details.Languages]["NoItems"]}</p>
        }

        <Modal
          backdrop={"static"}
          footer={null}
          visible={show}
          //props={this.state.data} 
          className="mopitstop1_repeat"
          onCancel={handleClose}  
          maskClosable={false}
        >
          <div className="draft_wrapfitting">
            <div className="app_stdfitter">
              <h4 className="app_stdfwrapinside">{Translation[details.Languages]["RepeatOrderConfirmation"]}</h4>
              <div className='app_btns'>
                <Button variant="secondary" style={{marginRight: '15px', borderRadius: '3px', color: '#000', border: '1px solid #000', margin:'5px',backgroundColor: '#fff'}} onClick={handleClose}>
                  {Translation[details.Languages]["No"]}
                </Button>
                <Button variant="primary" style={{borderRadius: '3px', color: '#fff', border: '1px solid #fff', margin:'5px',backgroundColor: 'rgb(35 41 66)'}} onClick={() => handleShow(repeatData)}>
                  {Translation[details.Languages]["Yes"]}
                </Button>
              </div>
            </div>
          </div>
        </Modal>  

<BookFreeConsultation bookModalOpen={bookModalOpen} closeBookModal={closeBookModal} repeatOrder={bookRepeatOrder} orderDetailsData={orderData} />

      </div>
</React.Fragment>
);
}

export default OrderListing;
