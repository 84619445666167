import React,{useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import ProfileImagechat from "../../images/Messages.svg";
import { useHistory } from "react-router";
import {logoutApp} from "../../actions/loginactions";
import Translation from "../../language/translate"
import moment from 'moment'
import { ClipLoader } from 'react-spinners';
import {chatnotificationList,updatechatstatus} from "../../actions/notification"
import "./index.css"

const Chatnotifications=(props)=>{
const details = useSelector(state => state);
const [loader, setloader]=useState(false)
const history = useHistory();
const dispatch = useDispatch();
const [notificationData, setNotificationData] = useState([])
useEffect(() => {
    onLoadGetData()
    }, [])

    const onLoadGetData=async()=>{
      setloader(true)
        let obj ={
            // "customer_id":"5f9139a92f7d150007742a92",
            "customer_id": localStorage.getItem('customer_id'),
        }
        const chatnotificationResponse = await dispatch(chatnotificationList(obj))
        console.log(chatnotificationResponse)
        if(chatnotificationResponse.data.status_code == 200){
            setloader(false)
            setNotificationData(chatnotificationResponse.data.unread_chat_list)
            props.chatnotificationLength(chatnotificationResponse.data.unread_chat_list.length)
        }
        else if(chatnotificationResponse.data.status_code == 408 || chatnotificationResponse.data.status_code == 402){
          let obj = {
              "customer_id": localStorage.getItem("customer_id"),
              "deviceType": "web"
          }
          const logoutResponse = await dispatch(logoutApp(obj));
          if(logoutResponse.data.status_code == 200){
              
              localStorage.clear();
          
              history.push({pathname:  "/"})
              window.location.reload()
              
              
          }
      }
        else{
          setloader(false)
        }
        
    }

   const orderdetailsview=async(orderId,chatType)=>{
    console.log(chatType)
  window.scroll(0,0)
  if(orderId!=undefined){
    props.closemeetdesignerModal()
    console.log(orderId)
    let obj ={
      "person_type":"customer",
      "person_id":localStorage.getItem('customer_id'),
      "order_id":orderId,
      "chat_type":chatType
  }
  const updatechatstatusResponse = await dispatch(updatechatstatus(obj))
  console.log(updatechatstatusResponse)
    history.push('/orderdetails/'+orderId)
  }
  
  }
return (
<React.Fragment>
{!loader?
notificationData.length?notificationData.map(notify=>(
    <div className="row notification-tile" onClick={()=>orderdetailsview(notify.order_id,notify.chat_type)}>
        <div className="col-2" style={{margin: "auto"}}>
          <img src={ProfileImagechat} alt="profile" />
        </div>
        <div className="col-8">
          <p className="notf-order-id">
            {notify.order_details[0].order_id}
          </p>
          <p className="notf-order-idmes">
            {notify.unread_messages} {Translation[details.Languages]["UnreadMesaages"]}
          </p>
          <p className="notf-contract-status">
            {details.Languages == "en" ? notify.notification_title : notify.notification_title_arabic}
          </p>
          <p className="notf-contract-description">
            {details.Languages == "en" ? notify.notification_content : notify.notification_content_arabic}
          </p>
        </div>
        <div className="col-2 notf-order-id">
            {moment(notify.updatedAt).format("MMM DD")}
        </div>
      </div>
))
:
<div className="no-notification">
    {Translation[details.Languages]["NoNotification"]}
</div>
:
<div className="loader_center">
<ClipLoader color={'#242E49'} loading={loader} />
</div>
}
</React.Fragment>
);
}

export default Chatnotifications;