import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {getKitchenStyle, chooseKitchenStyleFun} from "../../actions/bookFreeConsultationActions"
import Translation from "../../language/translate"
import Tick from "../../images/tick.png"
import { ClipLoader } from 'react-spinners';
import ProductListItem from "../../components/product-list-item-single"
import "./index.css"
import { No_Properties, SMEvent, SMEvent_Category } from '../../smartlook';

const ChooseKitchenStyle=(props)=>{
const details = useSelector(state => state);

const [loader, setloader]=useState(false)

const [mandatoryError, setMandatoryError] = useState(false)

const dispatch = useDispatch();

const [kitchenStyles, setKitchenStyles] = useState([])

const [styleId, setStyleId] = useState(details.chooseStyle.styleId ?
    details.chooseStyle.styleId : props.orderData.kitchen_style? props.orderData.kitchen_style : "")

const [styleSelectedArabic, setStyleSelectedArabic] = useState(details.chooseStyle.styleSelectedArabic ?
    details.chooseStyle.styleSelectedArabic : props.orderData.kitchen_style_category_name_arabic? props.orderData.kitchen_style_category_name_arabic : "")


const [styleSelected, setStyleSelected] = useState(details.chooseStyle.kitchenStyleSelected ?
details.chooseStyle.kitchenStyleSelected : props.orderData.kitchen_style_category_name? props.orderData.kitchen_style_category_name : "")
useEffect(() => {
setloader(true)
onLoad();
}, [])

const onLoad = async (userId) => {
const kitchenStyles = await dispatch(getKitchenStyle());
console.log(kitchenStyles);
if (kitchenStyles.data.status_code == 200) {
setloader(false)
setKitchenStyles(kitchenStyles.data.list)
}
else{
setloader(false)
}
}

const selectStyle =(styleType, styleTypeArabic, styleId)=>{
setStyleSelectedArabic(styleTypeArabic)
setStyleSelected(styleType)
setStyleId(styleId)

SMEvent(SMEvent_Category, No_Properties)
}

const kitchenStyleContinueButton=()=>{
    console.log(styleSelected)
    if(styleSelected !== ""){
        dispatch(chooseKitchenStyleFun(styleSelected, styleSelectedArabic, styleId))
        setMandatoryError(false)
        props.handleNext()
    }
    else{
        setMandatoryError(true)
    }

}


return (
<React.Fragment>
    <div className={mandatoryError ? "choose-kitchen-wrap choose-kitchen-wrap-with-error-padding" : "choose-kitchen-wrap choose-kitchen-wrap-padding"}>
        <div className="header-title">
            {Translation[details.Languages]["ChooseKitchen"]}
        </div>
        <div className="header-description">
            {Translation[details.Languages]["SelectBelow"]}
        </div>
        <div className="row" style={{marginTop: "6%"}}>
            {!loader?kitchenStyles.map(style=>(
            <span className="col-lg-6 col-md-12 col-sm-12" onClick={()=>selectStyle(style.category_name, style.category_name_arabic, style._id)}>
                {styleSelected == style.category_name?
                <div className={details.Languages == "en" ? "tick-div-class": "tick-div-class tick-div-class-arabic"}>
                    <img src={Tick} alt="tick" />
                </div>
                :""}
                <ProductListItem ProductImage={style.tile_image} headingContent={details.Languages == "en"? style.category_name: style.category_name_arabic} 
                    // descriptionContent={style.category_description}
                    descriptionContent= {details.Languages == "en"? style.category_description: style.category_description_arabic}
                    productPrice="" Currency="" language={details.Languages}/>
            </span>
            ))

            :
            <div className="loader_center">
                <ClipLoader color={'#242E49'} loading={loader} />
            </div>
            }

            <div className="row button-wrap">
                <div className="col-lg-6 col-md-6 col-sm-12 go-back-wrap">
                    <div className="go-back-button resp_gobkbtn" onClick={props.handlePrevious} style={details.Languages == "ar"?{float: "left"}:{float: "right"}}>
                        {Translation[details.Languages]["GoBackButton"]}
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 continue-wrap" >
                    <input className={details.Languages == "ar"? "continue-button float-right-arabic resp_gobkbtn" : "continue-button"} type="button" onClick={kitchenStyleContinueButton}
                        // disabled={styleSelected=="" ? true : false}
                        value={Translation[details.Languages]["ContinueButton"]} />
                </div>
                {mandatoryError?
                <div className="mandatory-error">
                    Choose any kitchen style
                </div>
                :""
                }
            </div>
        </div>
    </div>
</React.Fragment>
);
}

export default ChooseKitchenStyle;