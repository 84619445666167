export const formatOrderType = (orderType = "") => {
  function capitalizeFirstLetter(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  let formatedOrderType = "";

  orderType.split("_").forEach((type) => {
    if (formatedOrderType === "") {
      formatedOrderType = capitalizeFirstLetter(type);
    } else {
      formatedOrderType += ` ${capitalizeFirstLetter(type)}`;
    }
  });
  return formatedOrderType;
};

export const arabicToLatinMap = {
  "٠": "0",
  "١": "1",
  "٢": "2",
  "٣": "3",
  "٤": "4",
  "٥": "5",
  "٦": "6",
  "٧": "7",
  "٨": "8",
  "٩": "9",
  ".": ".",
};

export const arToNumbers = (input) => {
  return input.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (match) => arabicToLatinMap[match]);
};
