let InitialStateValue = {
    moreInformation : {}
}

const bookDemoMoreInformationReducer = (state = InitialStateValue, action) => {
    switch (action.type) {
     
      case 'DEMO_MORE_INFORMATION':
        return {
            moreInformation : {site:action.data.site, priceRange:action.data.priceRange, days: action.data.days, siteInfoArabic: action.data.siteInfoArabic, priceRangeArabic: action.data.priceRangeArabic}
        }
      default:
        return state;
    }
  };

export default bookDemoMoreInformationReducer