import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch} from "react-redux";
import DatePicker, { registerLocale } from "react-datepicker";
import {meetingDateAndTime} from "../../actions/bookFreeConsultationActions"
import ar from "date-fns/locale/ar-DZ"; // the locale you want
import moment from 'moment';
import Translation from "../../language/translate"
import "react-datepicker/dist/react-datepicker.css";
import "./index.css"

registerLocale("ar", ar);
const ReadyToMeet=(props)=>{
const dispatch = useDispatch();
const [choseContent, setChoseContent] = useState([])
const [currentTime, setCurrentTime] = useState([])
const [choseContentLength, setChoseContentLength] = useState([])
const [mandatoryError, setMandatoryError] = useState(false)
const details = useSelector(state => state);
const [timeScheduled, setTimeScheduled] = useState(details.meetDateAndTime.meetDateAndTime.time? details.meetDateAndTime.meetDateAndTime.time : "")
const [startDate, setStartDate] = useState(details.meetDateAndTime.meetDateAndTime.date? details.meetDateAndTime.meetDateAndTime.date : new Date());

let meetTimings = [
    {time: "08:00 AM", value: 8}, 
    {time: "09:00 AM", value: 9}, 
    {time: "10:00 AM", value: 10}, 
    {time: "11:00 AM", value: 11}, 
    {time: "12:00 PM", value: 12}, 
    {time: "01:00 PM", value: 13}, 
    {time: "02:00 PM", value: 14}, 
    {time: "03:00 PM", value: 15}, 
    {time: "04:00 PM", value: 16}, 
    {time: "05:00 PM", value: 17}, 
    {time: "06:00 PM", value: 18}, 
    {time: "07:00 PM", value: 19}
]

useEffect(() => {
    onLoad();
    setCurrentTime(parseInt(convertTime12to24(calculateMinTime(new Date())).split(":")[0]))
    }, []);

    const convertTime12to24 = (time12h) => {
        const [time, modifier] = time12h.split(' ');
      
        let [hours, minutes] = time.split(':');
      
        if (hours === '12') {
          hours = '00';
        }
      
        if (modifier === 'PM') {
          hours = parseInt(hours, 10) + 12;
        }
      
        return `${hours}:${minutes}`;
      }
      
    const onChangeSite = (e) => {
        if(e == "New Site" || e == "new_site") {
            return "جديد"
        } else return "قديم"
    };

    const onChangeRange = (value) => {
        if(value == "Economical")
            return "اقتصادي"
            else if(value == "Moderate")
            return "متوسط "
            else if(value == "Premium")
            return "عالي "
            else return"أي ميزانية, (غير مقيد الميزانية)"
    };

const onLoad = () => {
    // console.log("details in ready to meet :", details, "details.nonKitchen.nonkitchen.service != undefined :", details.nonKitchen.nonkitchen.service != undefined, details.nonKitchen.nonkitchen != {})

    //booking new changes comment starts here

    // if(details.nonKitchen.nonkitchen.service != undefined){
    //     let choseContent = [
    //         details.nonKitchen.nonkitchen.service? details.nonKitchen.nonkitchen.service : "-",
    //         details.nonKitchen.nonkitchen.kitchenOrder? `Kitchen Order ID :${details.nonKitchen.nonkitchen.kitchenOrder[0]}` : "No Kitchen order attached"
    //     ]

    //     let choseContentArabic = [
    //         details.nonKitchen.nonkitchen.serviceArabic? details.nonKitchen.nonkitchen.serviceArabic : "-",
    //         details.nonKitchen.nonkitchen.kitchenOrder? `Kitchen Order ID :${details.nonKitchen.nonkitchen.kitchenOrder[0]}` : "No Kitchen order attached"
    //     ]

    //     setChoseContent(details.Languages == "en"? choseContent: choseContentArabic)
    //     setChoseContentLength(choseContent.length)
    // }
    // else {
    // let choseContent = [
    //     details.designKitchen.kitchenSizeShape.shape? details.designKitchen.kitchenSizeShape.shape : props.orderData.kitchen_shape_title,
    //     details.designKitchen.kitchenSizeShape.size && details.designKitchen.kitchenSizeShape.sizeUnit? details.designKitchen.kitchenSizeShape.size+" " + details.designKitchen.kitchenSizeShape.sizeUnit +" " +"(Kitchen size)" : props.orderData.kitchen_size + " " + props.orderData.kitchen_size_dimension +" " +"(Kitchen size)", 
    //     details.chooseStyle.kitchenStyleSelected? details.chooseStyle.kitchenStyleSelected : props.orderData.kitchen_style_category_name,
    //     details.moreInformation.moreInformation.site? details.moreInformation.moreInformation.site == "New Site" || details.moreInformation.moreInformation.site == "new_site"? "New Site" : "Old Site"  : props.orderData.site_type == "New Site" || props.orderData.site_type == "new_site"? "New Site" : "Old Site",
    //     details.moreInformation.moreInformation.priceRange? details.moreInformation.moreInformation.priceRange : props.orderData.budget_range_type,
    //     details.moreInformation.moreInformation.days?
    //     details.moreInformation.moreInformation.days : (props.orderData.project_start_expected_by).split(" ")[0],
    // ]

    // let choseContentArabic = [
    //     details.designKitchen.kitchenSizeShape.shapeArabic? details.designKitchen.kitchenSizeShape.shapeArabic : props.orderData.kitchen_shape_title_arabic, 
    //     details.designKitchen.kitchenSizeShape.size && details.designKitchen.kitchenSizeShape.sizeUnit? details.designKitchen.kitchenSizeShape.size+" " + details.designKitchen.kitchenSizeShape.sizeUnit +" " +"(مقاس المطبخ)" : props.orderData.kitchen_size + " " + props.orderData.kitchen_size_dimension +" " +"(مقاس المطبخ)", 
    //     details.chooseStyle.kitchenStyleSelectedArabic? details.chooseStyle.kitchenStyleSelectedArabic : props.orderData.kitchen_style_category_name_arabic,
    //     details.moreInformation.moreInformation.siteInfoArabic? details.moreInformation.moreInformation.siteInfoArabic : onChangeSite(props.orderData.site_type),
    //     details.moreInformation.moreInformation.priceRangeArabic? details.moreInformation.moreInformation.priceRangeArabic : onChangeRange(props.orderData.budget_range_type),
    //     details.moreInformation.moreInformation.days?
    //     details.moreInformation.moreInformation.days : (props.orderData.project_start_expected_by).split(" ")[0],
    // ]
    // setChoseContent(details.Languages == "en"? choseContent: choseContentArabic)
    // setChoseContentLength(choseContent.length)
    // }

    //booking new changes comment ends here
    }

const meetingScheduleTime =(timeScheduled)=>{
    setTimeScheduled(timeScheduled)
}




const readyoMeetContinueButton =async()=>{
    if(timeScheduled == ""){
        setMandatoryError(true)
    }
    else{
        dispatch(meetingDateAndTime(startDate, timeScheduled))
        setMandatoryError(false)
        props.handleNext()
    }
 
}

const onSelectDate=(date)=>{
    setStartDate(date)
    setCurrentTime(calculateMinTime(date) ? parseInt(convertTime12to24(calculateMinTime(date))): "")
    setTimeScheduled("")
   
}

const calculateMinTime = date => {
    let isToday = moment(date).isSame(moment(), 'day');
    if (isToday) {
        let nowAddOneHour = moment().add(4,'hours').format('LT');
        return nowAddOneHour;
    }
}

console.log(" in ready to meet :", details, mandatoryError)
return (
<React.Fragment>
    <div className={mandatoryError ? "ready-to-meet-wrap ready-to-meet-with-error-wrap" : "ready-to-meet-wrap ready-to-meet-padding"} align={details.Languages == "ar"?"right":""}>
        <div className="header-title">
        {Translation[details.Languages]["ReadyToMeet"]}
        </div>
        <div className="header-description">
        {Translation[details.Languages]["MeetDesignerTime"]}
        </div>
        <div className="row" style={{padding: "60px 0px", display: 'flex', flexWrap: 'nowrap'}}>
            {/* <div className="row" style={{padding: "60px 0px"}}> */}
            <div className="col-lg-8 col-md-12 col-sm-12">
                {details.Languages == "en" ?<i className="fa fa-long-arrow-right long-arrow-header" aria-hidden="true"></i>:""}
                {Translation[details.Languages]["SelectDate"]}<span className="starRed">*</span>
                    {details.Languages == "ar" ? <i className="fa fa-long-arrow-left long-arrow-left-header" aria-hidden="true"></i>:""}
                <div className="date-time-wrap">
                    <DatePicker selected={startDate} 
                        onChange={date=> onSelectDate(date)}
                        className="date-picker-class"
                        calendarClassName="calender-class"
                        minDate={new Date()}
                        showPopperArrow={false}
                        locale={details.Languages === "ar" ? "ar" : ""}
                        inline
                        />
                        {/* <div className="meet-time">
                            {
                                meetTimings.map(time=>(
                                    <button 
                                        disabled={time.value <= currentTime ? true : false}
                                        onClick={()=>meetingScheduleTime(time.time)} className="time-class" 
                                        style={{
                                            backgroundColor: time.value <= currentTime? "#8c898938":timeScheduled == time.time ?"#FFCF6C":"", boxShadow: timeScheduled == time.time?"0 2px 64px 0 rgba(0,0,0,0.12)":currentTime > 19 ? "#8c898938" : ""}}>
                                        {time.time}
                                    </button>
                                ))
                            }
                        </div> */}
                </div>
            </div>

            <div className="meet-time">
                            {
                                meetTimings.map(time=>(
                                    <button 
                                        disabled={time.value <= currentTime ? true : false}
                                        onClick={()=>meetingScheduleTime(time.time)} className="time-class" 
                                        style={{
                                            backgroundColor: time.value <= currentTime? "#8c898938":timeScheduled == time.time ?"#FFCF6C":"", boxShadow: timeScheduled == time.time?"0 2px 64px 0 rgba(0,0,0,0.12)":currentTime > 19 ? "#8c898938" : ""}}>
                                        {time.time}
                                    </button>
                                ))
                            }
                        </div>

             {/* new booking changes comment starts here */}

            {/* <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="you-chose-card-info-screen">
                    <div className="you-chose-title">
                    {Translation[details.Languages]["YouChose"]}
                    <span className="you-chose-edit" onClick={props.handlePreviousReadyToMeet}><i className="fa fa-pencil-square-o"></i>Edit</span>
                    </div> 
                    <ul className={details.Languages == "en"?"chose-info": "chose-info chose-info-arabic"}>
                    {
                        choseContent.map((list, i)=>
                        choseContentLength === i + 1 ?
                        <li>
                             {details.Languages == "en" ? <i className="fa fa-long-arrow-right long-arrow-class-more-info" aria-hidden="true"></i>:""}
                            {details.nonKitchen.nonkitchen.service != undefined? list : details.Languages == "en"?
                            "Need by " + list + " months"
                            :
                            "تحتاج من قبل " + list + " الشهور"
                            } 
                            {details.Languages == "ar" ? <i className="fa fa-long-arrow-left long-arrow-left-class-more-info" aria-hidden="true"></i>:""}
                        </li>
                        : 
                        <li>
                          {details.Languages == "en" ? <i className="fa fa-long-arrow-right long-arrow-class-more-info" aria-hidden="true"></i>:""}
                                {details.nonKitchen.nonkitchen.service != undefined ? list : list && list.includes("site")? list === "New Site"|| list === "new_site"? "New Site" : "Old Site" : list }
                            {details.Languages == "ar" ? <i className="fa fa-long-arrow-left long-arrow-left-class-more-info" aria-hidden="true"></i>:""}
                        </li>
                        )
                        }
                    </ul>
                </div>
            </div> */}
            {/* new booking changes comment ends here */}
        </div>
        <div className="row button-wrap">
                <div className="col-lg-6 col-md-6 col-sm-12 go-back-wrap">
                    <div className={details.Languages == "en" ? "go-back-button": "go-back-button float-left-arabic resp_gobkbtn"} onClick={props.handlePrevious}>
                    {Translation[details.Languages]["GoBackButton"]}
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 continue-wrap">
                    <input class="continue-button"
                    type="button"
                    onClick={readyoMeetContinueButton}
                    // disabled={timeScheduled == "" ? true : false}
                    value={Translation[details.Languages]["ContinueButton"]}/>
                </div>
                {mandatoryError?
                <div className="mandatory-error">
                    Time is mandatory
                </div>
                :""
                }
            </div>
    </div>
</React.Fragment>
);
}

export default ReadyToMeet;