import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Translation from "../../language/translate";
import { bookADemoRepeatAction } from "../../actions/bookFreeConsultationActions";
import { Sentry } from "react-activity";
import { PubNubProvider, PubNubConsumer } from "pubnub-react";
import ThankYouTick from "../../images/thankyoutick.png";
import PubNub from "pubnub";
import config from "../../config/properties";
import "react-activity/dist/react-activity.css";
import "./index.css";
import {
  SMEvent,
  SMEvent_Booking,
  SMEvent_Book_consultation,
  SMEvent_P_Booking_completed,
} from "../../smartlook";
// import * as CryptoJS from 'crypto-js'
// import sha256 from 'crypto-js/sha256';
// import hmacSHA512 from 'crypto-js/hmac-sha512';
// import Base64 from 'crypto-js/enc-base64';

const pubnub = new PubNub(config.PUBNUB);

const Confirmation = (props) => {
  const history = useHistory();
  const [designerConfirmed, setDesignerConfirmed] = useState(false);
  const dispatch = useDispatch();
  const Completionist = () => <span>Done</span>;
  const details = useSelector((state) => state);
  // Renderer callback with condition
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span>
          {minutes}:{seconds} {"MIN"}
        </span>
      );
    }
  };

  //encrypt
  // const secretKey = process.env.REACT_APP_SECRET_KEY ? process.env.REACT_APP_SECRET_KEY : '12345'
  // const encrypt = ( data ) => {
  //   const cipherText = CryptoJS.SHA256(data);
  //     // const cipherText = CryptoJS.AES.encrypt(data, secretKey).toString()
  //     return cipherText
  // }

  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log(props.noDesigner, "props.noDesigner")
    if (props.noDesigner && props.type != "otherOrder") {
      let counterSet = +localStorage.getItem("counter") + 1;
      localStorage.setItem("counter", counterSet);
      // console.log(localStorage.getItem("counter"), "nnnnnnnnnnnnnn")

      setTimeout(() => bookADemoRepeat(), 3000);
    }
    window.dataLayer.push({
      event: "booking_success",
      enhanced_conversion_data: {
        name:
          details.bookADemoValue &&
          details.bookADemoValue.bookADemoValue &&
          details.bookADemoValue.bookADemoValue.customerDetails &&
          details.bookADemoValue.bookADemoValue.customerDetails.name,
        email:
          details.bookADemoValue &&
          details.bookADemoValue.bookADemoValue &&
          details.bookADemoValue.bookADemoValue.customerDetails &&
          details.bookADemoValue.bookADemoValue.customerDetails.email,
        mobile_number:
          details.bookADemoValue &&
          details.bookADemoValue.bookADemoValue &&
          details.bookADemoValue.bookADemoValue.customerDetails &&
          details.bookADemoValue.bookADemoValue.customerDetails.country_code &&
          details.bookADemoValue.bookADemoValue.customerDetails.mobile_number
            ? `+${details.bookADemoValue.bookADemoValue.customerDetails.country_code}${details.bookADemoValue.bookADemoValue.customerDetails.mobile_number}`
            : undefined,
        street:
          details.bookADemoValue &&
          details.bookADemoValue.bookADemoValue &&
          details.bookADemoValue.bookADemoValue.customerDetails &&
          details.bookADemoValue.bookADemoValue.customerDetails.street,
        city:
          details.bookADemoValue &&
          details.bookADemoValue.bookADemoValue &&
          details.bookADemoValue.bookADemoValue.customerDetails &&
          details.bookADemoValue.bookADemoValue.customerDetails.city,
        country:
          details.bookADemoValue &&
          details.bookADemoValue.bookADemoValue &&
          details.bookADemoValue.bookADemoValue.customerDetails &&
          details.bookADemoValue.bookADemoValue.customerDetails.country,
        zip_code:
          details.bookADemoValue &&
          details.bookADemoValue.bookADemoValue &&
          details.bookADemoValue.bookADemoValue.customerDetails &&
          details.bookADemoValue.bookADemoValue.customerDetails.pincode,
      },
    });
    SMEvent(SMEvent_Booking, SMEvent_P_Booking_completed);
    localStorage.removeItem("parent_Kitchen_order");

    // console.log("the value of the details after success :", details)

    //tiktok
    window.ttq.track("SubmitForm", {
      content_name:
        details.bookADemoValue &&
        details.bookADemoValue.bookADemoValue &&
        details.bookADemoValue.bookADemoValue.customerDetails &&
        details.bookADemoValue.bookADemoValue.customerDetails.name,
      content_number:
        details.bookADemoValue &&
        details.bookADemoValue.bookADemoValue &&
        details.bookADemoValue.bookADemoValue.customerDetails &&
        details.bookADemoValue.bookADemoValue.customerDetails.mobile_number,
      content_email:
        details.bookADemoValue &&
        details.bookADemoValue.bookADemoValue &&
        details.bookADemoValue.bookADemoValue.customerDetails &&
        details.bookADemoValue.bookADemoValue.customerDetails.email,
      content_address:
        details.bookADemoValue &&
        details.bookADemoValue.bookADemoValue &&
        details.bookADemoValue.bookADemoValue.customerDetails &&
        details.bookADemoValue.bookADemoValue.customerDetails.address,
    });

    // //email encryption
    // const val = encrypt(details.bookADemoValue && details.bookADemoValue.bookADemoValue && details.bookADemoValue.bookADemoValue.customerDetails && details.bookADemoValue.bookADemoValue.customerDetails.email)

    // //number encryption
    // const num = encrypt(details.bookADemoValue && details.bookADemoValue.bookADemoValue && details.bookADemoValue.bookADemoValue.customerDetails && details.bookADemoValue.bookADemoValue.customerDetails.mobile_number)

    //snapchat - NiuliBookingCompletedSnap
    window.snaptr("track", "CUSTOM_EVENT_1", {
      name:
        details.bookADemoValue &&
        details.bookADemoValue.bookADemoValue &&
        details.bookADemoValue.bookADemoValue.customerDetails &&
        details.bookADemoValue.bookADemoValue.customerDetails.name,
      number:
        details.bookADemoValue &&
        details.bookADemoValue.bookADemoValue &&
        details.bookADemoValue.bookADemoValue.customerDetails &&
        details.bookADemoValue.bookADemoValue.customerDetails.mobile_number,
      email:
        details.bookADemoValue &&
        details.bookADemoValue.bookADemoValue &&
        details.bookADemoValue.bookADemoValue.customerDetails &&
        details.bookADemoValue.bookADemoValue.customerDetails.email,
      address:
        details.bookADemoValue &&
        details.bookADemoValue.bookADemoValue &&
        details.bookADemoValue.bookADemoValue.customerDetails &&
        details.bookADemoValue.bookADemoValue.customerDetails.address,
    });

    // window.snaptr('track', 'NiuliBookingCompletedSnap', { "name": details.bookADemoValue && details.bookADemoValue.bookADemoValue && details.bookADemoValue.bookADemoValue.customerDetails && details.bookADemoValue.bookADemoValue.customerDetails.name, "address" :details.bookADemoValue && details.bookADemoValue.bookADemoValue && details.bookADemoValue.bookADemoValue.customerDetails && details.bookADemoValue.bookADemoValue.customerDetails.address});

    // facebook
    window.fbq("track", "NiuliBookingCompletedFB", {
      name:
        details.bookADemoValue &&
        details.bookADemoValue.bookADemoValue &&
        details.bookADemoValue.bookADemoValue.customerDetails &&
        details.bookADemoValue.bookADemoValue.customerDetails.name,
      number:
        details.bookADemoValue &&
        details.bookADemoValue.bookADemoValue &&
        details.bookADemoValue.bookADemoValue.customerDetails &&
        details.bookADemoValue.bookADemoValue.customerDetails.mobile_number,
      email:
        details.bookADemoValue &&
        details.bookADemoValue.bookADemoValue &&
        details.bookADemoValue.bookADemoValue.customerDetails &&
        details.bookADemoValue.bookADemoValue.customerDetails.email,
      address:
        details.bookADemoValue &&
        details.bookADemoValue.bookADemoValue &&
        details.bookADemoValue.bookADemoValue.customerDetails &&
        details.bookADemoValue.bookADemoValue.customerDetails.address,
    });
  }, []);

  const bookADemoRepeat = async () => {
    // console.log(details, "detailsdetailsdetails")
    let obj = {
      customer_id: localStorage.getItem("customer_id"),
      id: details.bookADemoValue.bookADemoValue.orderID,
      meeting_date_and_time:
        details.meetDateAndTime.meetDateAndTime.date.toISOString(),
      meeting_time: details.meetDateAndTime.meetDateAndTime.time,
      longitude: details.bookADemoValue.bookADemoValue.meetingLongitude,
      latitude: details.bookADemoValue.bookADemoValue.meetingLatitude,
      country: details.bookADemoValue.bookADemoValue.meetingCountry,
      city: details.bookADemoValue.bookADemoValue.meetingCity,
      token: localStorage.getItem("user_token"),
      hit: localStorage.getItem("counter"),
    };
    const bookADemoRepeatActionResponse = await dispatch(
      bookADemoRepeatAction(obj)
    );
    let counterSet = +localStorage.getItem("counter") + 1;
    if (
      bookADemoRepeatActionResponse.data.status_code == 201 &&
      counterSet <= 2
    ) {
      localStorage.setItem("counter", counterSet);
      // console.log(localStorage.getItem("counter"), "first")

      setTimeout(() => bookADemoRepeat(), 3000);
    } else if (
      bookADemoRepeatActionResponse.data.status_code == 201 &&
      counterSet == 3
    ) {
      // console.log(localStorage.getItem("counter"), "second")
      props.handleNext();
    } else {
      props.handleNext();
    }
  };

  const orderDetails = () => {
    if (props.type == "kitchen")
      history.push(
        "/orderdetails/" + details.bookADemoValue.bookADemoValue.orderID
      );
    else if (props.type == "otherOrder")
      history.push(
        "/appliancedetails/" + details.bookADemoValue.bookADemoValue.orderID
      );
    else
      history.push(
        "/orderdetails/" + details.bookADemoValue.bookADemoValue.orderID
      );

    props.handleClose();
  };
  // const channels = ["request_"+details.bookADemoValue.bookADemoValue.orderID];
  // console.log("request_"+details.bookADemoValue.bookADemoValue.orderID)
  return (
    <React.Fragment>
      <PubNubProvider client={pubnub}>
        <div className="consultation-confirmation">
          <PubNubConsumer>
            {(client) => {
              client.subscribe({
                channels: [
                  "request_" + details.bookADemoValue.bookADemoValue.orderID,
                ],
              });
              client.addListener({
                message: function (msg) {
                  if (msg.message.status == "order_accepted") {
                    setDesignerConfirmed(true);
                    // props.handleNext("designerAccepted")
                  }
                },
              });
            }}
          </PubNubConsumer>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              {props.noDesigner && props.type != "otherOrder" ? (
                <div className="loader_center">
                  <div
                    className={`${
                      details.Languages === "ar" ? "loader_center_ar" : ""
                    }`}
                  >
                    <Sentry
                      size={150}
                      speed={0.6}
                      animating={true}
                      color="#FCCC6A"
                    />
                  </div>
                  <div className="header-description waiting-designer-text">
                    Please wait searching nearby designers
                  </div>
                </div>
              ) : designerConfirmed == true ? (
                <span>
                  <div className="thank-you-tick-class">
                    <img
                      src={ThankYouTick}
                      className="thank-you"
                      alt="thankyou"
                    />
                  </div>
                  <div className="header-title">DESIGNER ACCEPTED</div>

                  <div className="header-description">
                    Designer has accepted your request and will contact you
                    shortly!
                  </div>
                </span>
              ) : (
                <span>
                  <div className="header-title">
                    {Translation[details.Languages]["ThanksConfirmation"]}
                  </div>
                  <div
                    className="header-description"
                    style={{ width: "100%", float: "left" }}
                  >
                    {
                      Translation[details.Languages][
                        "ThanksConfirmationDescription"
                      ]
                    }
                  </div>
                  <div className="timer-div">
                    <Countdown date={Date.now() + 900000} renderer={renderer} />
                  </div>
                </span>
              )}
            </div>
          </div>
        </div>
        {props.noDesigner ? (
          <div className="got-it-button" onClick={props.onClickGotIt}>
            {Translation[details.Languages]["Cancel"]}
          </div>
        ) : designerConfirmed == true ? (
          <div
            className="got-it-button"
            style={{ marginTop: "-11%" }}
            onClick={orderDetails}
          >
            View Order Details
          </div>
        ) : (
          <div className="got-it-button" onClick={props.onClickGotIt}>
            {Translation[details.Languages]["OkGotItButton"]}
          </div>
        )}
      </PubNubProvider>
    </React.Fragment>
  );
};

export default Confirmation;
