import axios from "axios";
import config from "../config/properties";


export const coustomerProfileDetails = (param) => {
    return async () => {
        try {
            const coustomerProfileDetailsRequest = await axios.post(config.base_url + "customer/getProfileDetails", param,
            {headers: {
                'Content-Type': 'application/json',
            }})
                return coustomerProfileDetailsRequest;
        } catch (e) {}
    }
}

export const updateUserDetails  = (param) => {
    return async () => {
        try {
            const updateUserDetailsRequest = await axios.post(config.base_url + "customer/updateProfileDetails", param,
            {headers: {
                'Content-Type': 'application/json',
            }})
                return updateUserDetailsRequest;
        } catch (e) {}
    }
}

export const getOtpOfUpdatedNumber  = (param) => {
    return async () => {
        try {
            
            const getOtpOfUpdatedNumberRequest = await axios.post(config.base_url + "customer/changePhoneNumber", param,
            {headers: {
                'Content-Type': 'application/json',
            }})
            
            return getOtpOfUpdatedNumberRequest;
        } catch (e) {}
    }
}

export const verifyotp  = (param) => {
    return async () => { 
        try {
            const getOtpOfUpdatedNumberRequest = await axios.post(config.base_url + "customer/verifyOTPAndUpdatePhoneNumber", param,
            {headers: {
                'Content-Type': 'application/json',
            }})
                return getOtpOfUpdatedNumberRequest;
        } catch (e) {}
    }
}