import React from 'react';
import "./index.css"

const ProductListItem=(props)=>{
return (
<React.Fragment>
    <div className={props.className}>
        <div className="project-list-item">
            <div className="img">
                <img data-src={props.ProductImage} alt="" className="lazyload photo"/>
            </div>
            <div className={props.language == "en"?"text": "text text-arabic-product"}>
                <h3>{props.headingContent}</h3>
                <p>{props.descriptionContent}
                </p>
                <p className="product-price"><span style={{fontSize: "18px"}}>{props.Currency}</span>
                    {props.productPrice}</p>
            </div>
        </div>
    </div>
</React.Fragment>
);
}

export default ProductListItem;