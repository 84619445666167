let InitialStateValue = {
    kitchenSizeShape : {}
}

const designKitchenReducer = (state = InitialStateValue, action) => {
    switch (action.type) {
     
      case 'KITCHEN_SIZE_SHAPE':
        return {
            kitchenSizeShape : {size:action.data.size, shape:action.data.shape, shapeArabic: action.data.shapeArabic, sizeUnit: action.data.sizeUnit, shapeId: action.data.shapeId}
        }
      default:
        return state;
    }
  };

  
export default designKitchenReducer