


const initialState = {
    applianceOrderDetailValue: [],
    showLoader: false
};

const applianceorderdetails = (state = initialState, action) => {
    switch (action.type) {
        case "APPLIORDERDETAIL_SUCCESS":
            return {...state, applianceOrderDetailValue: action.success.data.order_details[0], showLoader: false};

        case "APPLIORDERDETAIL_FAILURE":
            return {...state, showLoader: false, applianceOrderDetailValue: []};
        
        case "APPLIORDERDETAIL_REQUESTING":
            return {...state, showLoader: true, applianceOrderDetailValue: []};
        
        default:
            return state;
    }
};

export default applianceorderdetails;