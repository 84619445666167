import React,{useEffect, useState, useLayoutEffect} from 'react'
import { useDispatch,useSelector} from "react-redux";
import Kitchencarouselcard from "../../components/kitchencarouselcard";
import {Modal} from "antd";
import { useHistory } from "react-router";
import Translation from "../../language/translate"
import moment from 'moment'
import {getDesignerDetails} from "../../actions/designerDetailsAction"
import {notificationList,updatenotificationstatus} from "../../actions/notification"
import { ClipLoader } from 'react-spinners';
import star_ratedesigner from "../../images/design_ratestar.png"
import callphone from "../../images/call_phone.png"
import {logoutApp} from "../../actions/loginactions";
import { Row, Col } from "react-bootstrap";
import "antd/dist/antd.css";
import "./index.css";


const Meetdesignerswithslot = (props) => {
const details = useSelector(state => state);
const [designerDetails, setDesignerDetails] = useState([])
const [orderDetail, setOrderDetail] = useState([])
const [carouselImages, setcarouselImages] = useState([])
const [loader, setloader]=useState(false)
const dispatch = useDispatch();
const history = useHistory();

 
const designerwithslotend =async()=>{
  handleCancelmeetdesigner()
  if(props.notifnvalue == "notification"){
  props.closedrawer("")
  }
}


const handleCancelmeetdesigner = async() => {
props.closemeetdesignerModal();
setcarouselImages([])
if(props.notifnvalue == "notification"){
  props.closedrawer("")
}
}

useEffect(() => {
if(props.meetdesignerModal == true){
  setloader(true)
  onload()
}
}, [props.orderId, props.meetdesignerModal])

useEffect(()=>{
  notificationfetch()
},[props.notifnvalue == "notification"])

const notificationfetch=async()=>{
  if(props.notifnvalue == "notification" && props.readstatus == false){
    if(props.notificationId!=undefined){
      let obj={
        "id":props.notificationId,
        "token": localStorage.getItem('user_token')
      }
    const updatenotificationstatusResponse = await dispatch(updatenotificationstatus(obj))
    console.log(updatenotificationstatusResponse)
    }
  }
}

const onload = async() =>{
  let obj ={
    "id": props.orderId,
    "token": localStorage.getItem('user_token')
}
  const designerDetail = await dispatch(getDesignerDetails(obj));

  console.log(designerDetail, "designerDetail")
  if(designerDetail.data.status_code == 200){
    setOrderDetail(designerDetail.data.order_details[0])
    setDesignerDetails(designerDetail.data.order_details[0].designer_details[0])
    // carouselImagesFun(designerDetail.data.order_details[0].designer_details[0])
    setcarouselImages(designerDetail.data.order_details[0].designer_details[0].uploaded_work_images)
    setloader(false)
  }
  else if(designerDetail.data.status_code == 408 || designerDetail.data.status_code == 402){
    let obj = {
        "customer_id": localStorage.getItem("customer_id"),
        "deviceType": "web"
    }
    const logoutResponse = await dispatch(logoutApp(obj));
    if(logoutResponse.data.status_code == 200){
        
        localStorage.clear();
    
        history.push({pathname:  "/"})
        window.location.reload()
        
        
    }
}
  
}

// const carouselImagesFun = (data) =>{
//   let imageArr = data.uploaded_work_images.map(images =>(
//     <div>
//         {images}
//     </div>
//   ))

//   setcarouselImages(imageArr)
// }


useLayoutEffect(() => {
  if (props.meetdesignerModal === true) {
    document.body.style.position = 'fixed';
  }
  else{
      document.body.style.position = 'unset';
  }
}, [props.meetdesignerModal]); 

return(
<div className="app_std">
  <Modal backdrop={"static"} 
  //title="Niuli header" 
    footer={null}
    visible={props.meetdesignerModal} 
    //props={this.state.data} 
    className="mopitstop"
    onCancel={handleCancelmeetdesigner} maskClosable={false}>
    <Row>
      <Col md={12} className="remvapp_padng">
      <div className="">
        <div className="draft_wrapdesign_img">
          <h4 className="inti_Scrennap">{Translation[details.Languages]["MeetTheDesignerTitle"]}</h4>
          <span className="draft_wrapinnerdes">{Translation[details.Languages]["BestDesigners"]} </span>
          { loader?
              <div className="loader_center">
                <ClipLoader color={'#242E49'} loading={loader} />
              </div>
              :
          <span>    
          <span className="draft_wrapitime"> on {orderDetail !== undefined? moment(orderDetail.meeting_date_and_time).format("ddd MMM Do YYYY,") + (orderDetail.meeting_time): ""}</span>
          <div className="col-lg-12 meetdes_app">
            <div className="col-lg-5 col-md-6 col-sm-12">
              <div className="app_designleft">
                <span className="app_designleftinner">
                  <img src={designerDetails !== undefined? designerDetails.profile_image:""} alt="" style={{width: "128px", height: "128px", borderRadius: "50%"}}/>
                  {/* <img className="video_secplay" src={videoplay_appbtn} alt=""/> */}
                </span>
                <div className="app_std">
                  <span className="designer_nameinner">{designerDetails !== undefined? details.Languages == "en" ?designerDetails.name: designerDetails.name_arabic:""}</span>
                  <span className="designer_nameappexp">{designerDetails !== undefined? designerDetails.experience:""} {Translation[details.Languages]["Yrs"]} {Translation[details.Languages]["Exp"]}
                    <span className={details.Languages == "en"?"starsec":"starsecar"}><img className={details.Languages == "en"?"star_inner":"star_innerar"} src={star_ratedesigner} alt=""/>{designerDetails.rating !== undefined? designerDetails.rating.toFixed(1):"0.0"}</span>
                  </span>
                  <span className="designer_nameappmeet">
                    {designerDetails !== undefined?details.Languages == "en" ? designerDetails.specialization : designerDetails.specialization_arabic:""}
                  </span>
                  <span className="designer_namecall">
                  <a className="tel_appokj" href={"tel" +':'+ designerDetails.mobile_number} >
                    <img className="box_applfix lazyload" data-src="https://s3.ap-south-1.amazonaws.com/niuli-images/1602328296729-niuli-box_login.png" alt=""/>
                    <img className={details.Languages == "en"?"call_phoneno":"call_phonenoar"} src={callphone} alt=""/>
                    {designerDetails.country_code + "-" + designerDetails.mobile_number}
                    </a>
                  </span>
                </div>
              </div>
            </div>

            <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12 designer_modalch">
              <Kitchencarouselcard fetchimage={carouselImages}/>
            </div>
          </div>

          <div className="app_std">
            <div className="dark-btnorderatgdsgn" onClick={designerwithslotend}>
              <span className="show-btnorderatgdsgn">{Translation[details.Languages]["OkGotItButton"]}</span>
              <span className="hide-btnorderatgdsgn">{Translation[details.Languages]["OkGotItButton"]}</span>
            </div>
          </div>
          </span>
} 
        </div>
      </div>
      </Col>
    </Row>
  </Modal>

</div>
)
}

export default (Meetdesignerswithslot)