let InitialStateValue = {
    kitchenStyleSelected: ''
}

const chooseStyleReducer = (state = InitialStateValue, action) => {
    switch (action.type) {

      case 'CHOOSE_KITCHEN_STYLE':
        return{
            kitchenStyleSelected: action.data.style,
            kitchenStyleSelectedArabic: action.data.styleArabic,
            styleId : action.data.styleId
        }
      default:
        return state;
    }
  };

export default chooseStyleReducer