let InitialStateValue = {
  bookADemoValue:{}
}

const bookDemoReducer = (state = InitialStateValue, action) => {
  console.log(action, "actionactionactionactionaction")
    switch (action.type) {

      case 'BOOK_A_DEMO_RESPONSE':
        return{
          bookADemoValue:{
            meetingCountry: action.data.meetingCountry, 
            meetingLongitude: action.data.meetingLongitude, 
            meetingLatitude: action.data.meetingLatitude,
            meetingCity: action.data.meetingCity,
            orderID: action.data.orderID,
            customerDetails: action.data.customerDetails
          }
        }
      default:
        return state;
    }
  };

export default bookDemoReducer