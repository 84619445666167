import axios from "axios";
import config from "../config/properties";


export const notificationList = (param) => {
    return async () => {
        try {
            const notificationListRequest = await axios.post(config.base_url + "customer/getNotificationById", param,
            {headers: {
                'Content-Type': 'application/json',
            }})
                return notificationListRequest;
        } catch (e) {}
    }
}

export const chatnotificationList = (param) => {
    return async () => {
        try {
            const chatnotificationListRequest = await axios.post(config.base_url + "customer/allUnreadChatCountAndListForCustomer", param,
            {headers: {
                'Content-Type': 'application/json',
            }})
                return chatnotificationListRequest;
        } catch (e) {}
    }
}

export const updatechatstatus = (param) => {
    return async () => {
        try {
            const updatechatstatusRequest = await axios.post(config.base_url + "chat/updateChatReadStatus", param,
            {headers: {
                'Content-Type': 'application/json',
            }})
                return updatechatstatusRequest;
        } catch (e) {}
    }
}

export const getchatnotificationcount = (param) => {
    return async () => {
        try {
            const getchatnotificationcountRequest = await axios.post(config.base_url + "customer/getHeaderDetails", param,
            {headers: {
                'Content-Type': 'application/json',
            }})
                return getchatnotificationcountRequest;
        } catch (e) {}
    }
}

export const updatenotificationstatus = (param) => {
    return async () => {
        try {
            const updatenotificationstatusRequest = await axios.post(config.base_url + "customer/updateNotificationReadStatus", param,
            {headers: {
                'Content-Type': 'application/json',
            }})
                return updatenotificationstatusRequest;
        } catch (e) {}
    }
}

export const updatesupportcenterstatus = (param) => {
    return async () => {
        try {
            const updatesupportcenterstatusRequest = await axios.post(config.base_url + "customer/createEnquiry", param,
            {headers: {
                'Content-Type': 'application/json',
            }})
                return updatesupportcenterstatusRequest;
        } catch (e) {}
    }
}

export const languagechangestatus = (param) => {
    return async () => {
        try {
            const languagechangestatusRequest = await axios.post(config.base_url + "customer/updateCustomerAppLanguage", param,
            {headers: {
                'Content-Type': 'application/json',
            }})
                return languagechangestatusRequest;
        } catch (e) {}
    }
}