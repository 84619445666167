import React,{useState,useEffect } from 'react';
import { useDispatch } from "react-redux";
import Autocomplete from "react-google-autocomplete";
import {getAllListingDetailsForHome} from "../../actions/orderlistingactions";
import { useHistory } from "react-router";
import {getallcountries} from "../../actions/loginactions"
import {logoutApp} from "../../actions/loginactions";
import emptylocate from "../../images/empty_loc_fetch.png"
//import "./index.css"

let location = React.createRef();

const Locationheader=(props)=>{
    const history = useHistory();
console.log(props)
const dispatch = useDispatch();
const [accountdetails, setcreateaccountmodule] = useState({})
const [allcountrieslist,setallcountrieslist] = useState([])
const [lcationerror,setlocationerror] = useState("")
const [searcherror,setsearcherror] = useState("")
const [designersList,setdesignersList] =useState([])
const [checkcountry,setcheckcountry] =useState(true)

useEffect(() => {
    onLoad()
  }, []);

const adressDetailsOnChange=(e)=>{
    console.log(e.target.value)
    setlocationerror("")
    setsearcherror("")
}

const onLoad = async() => {
    const countryresponse = await dispatch(getallcountries())
    console.log(countryresponse)
    if(countryresponse.data.status_code == 200){
        setallcountrieslist(countryresponse.data.configuration_list.map((allList)=>(allList.country_code)))
    }
    }

const fetchlocation=(place)=>{
    console.log(place)
   if(place.geometry){
    var checkcuntry = allcountrieslist 
   // var n = checkcuntry.includes(accountdetails.userCountry)
    setcreateaccountmodule({
        userlocation:place,
        user_lcationcord:{
            type: "Point",
        coordinates: [
          place.geometry.location.lng(),
          place.geometry.location.lat()
        ]
        },
        latitude:localStorage.setItem('latitude',place.geometry.location.lat().toString()),
        longitude:localStorage.setItem('longitude',place.geometry.location.lng().toString()) ,
        useraddress:localStorage.setItem('address',place.formatted_address),
        userlocationcity:place.address_components!=undefined?localStorage.setItem('user_city',place.address_components.find(addr => {return addr.types[0] == "locality" || addr.types[1] == "locality"}).long_name):"",
        userLocationCountry:place.address_components!=undefined?place.address_components.find(addr => {return addr.types[0] == "country"}).long_name:"",
        userCountry:place.address_components!=undefined?place.address_components.find(addr => {return addr.types[0] == "country"}).short_name:"",
    })
    setcheckcountry(checkcuntry.includes(place.address_components!=undefined?place.address_components.find(addr => {return addr.types[0] == "country"}).short_name:""))
    console.log(accountdetails.useraddress)
    setlocationerror("")
    setsearcherror("")
    listingDetailsForHome()
    props.closedrawer()
}
else{
    setlocationerror("NO RESULTS")
    setsearcherror("Are you sure you entered the right location?")
}
}

const listingDetailsForHome = async() =>{
    let obj = {
        "location":{ 
            "type":"Point", 
            "coordinates":[localStorage.getItem('longitude'),localStorage.getItem('latitude')] 
        },
        "city": localStorage.getItem('user_city'),
        "token":localStorage.getItem("user_token"),
        "customer_id":localStorage.getItem("customer_id"),
        "platform": "web"
    }
    const listHomeResponse= await dispatch(getAllListingDetailsForHome(obj))
    console.log(listHomeResponse)
    if(listHomeResponse.data.status_code ==200){
        setdesignersList(listHomeResponse.data.active_designer_list)
    }
    else if(listHomeResponse.data.status_code == 408 || listHomeResponse.data.status_code == 402){
        let obj = {
            "customer_id": localStorage.getItem("customer_id"),
            "deviceType": "web"
        }
        const logoutResponse = await dispatch(logoutApp(obj));
        if(logoutResponse.data.status_code == 200){
            
            localStorage.clear();
        
            history.push({pathname:  "/"})
            window.location.reload()
            
            
        }
    }
}

var checkcuntry = allcountrieslist 
 var n = checkcuntry.includes(accountdetails.userCountry)
 console.log(n)
  
return (
   
<React.Fragment>
  
  <div> 
        {console.log(accountdetails.user_lcationcord)}
        {console.log(checkcountry)}
      <div className="row notification-tile">
        <div className="col-12" style={{margin: "auto"}}>
        {
         checkcountry==true?   
        <Autocomplete
                      ref={location}
                      className="pwd_fieldsinnerlocate"
                      onPlaceSelected={fetchlocation}
                      types={[]}
                      onChange={e => adressDetailsOnChange(e) }
                      componentRestrictions={{country: ["ae","sa","qa","in","kw","bh", "om"]}}
            />
           :"dhihi"
        }
        </div>
      </div>
            {
                lcationerror == "NO RESULTS"?
            <div className="error_imagemap">
                <img className="error_imagemapwrap" src={emptylocate}  />
            </div>:""
            }
            <h5 className="lo_ero_map">{lcationerror}</h5>
            <span className="lo_ero_mapwrap">{searcherror}</span>
      </div>
</React.Fragment>
);
}

export default Locationheader;