import React,{useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import ProfileImagenot from "../../images/Notifications.svg";
import { useHistory } from "react-router";
import {logoutApp} from "../../actions/loginactions";
import Translation from "../../language/translate"
import moment from 'moment'
import { ClipLoader } from 'react-spinners';
import {notificationList,updatenotificationstatus} from "../../actions/notification"
import { gaPageView, gaEvent } from '../../googleAnalytics';
import { SMEvent, SMEvent_notification_open, No_Properties } from '../../smartlook';
import "./index.css"

const Notification=(props)=>{
const details = useSelector(state => state);
const [loader, setloader]=useState(false)
const history = useHistory();
const dispatch = useDispatch();
const [notificationData, setNotificationData] = useState([])
const [unreadcount,setunreadcount]= useState("")
useEffect(() => {
    onLoadGetData()
    }, [])

    const onLoadGetData=async()=>{
      setloader(true)
        let obj ={
            "id": localStorage.getItem('customer_id'),
            "notification_for":"customer",
            "page":0,
            "size":200,
            "token": localStorage.getItem('user_token')
        }
        const notificationResponse = await dispatch(notificationList(obj))
        console.log(notificationResponse)
        if(notificationResponse.data.status_code == 200){
            setloader(false)
            setNotificationData(notificationResponse.data.notification)
            props.notificationLength(notificationResponse.data.notification.length)
            setunreadcount(notificationResponse.data.count)
        }
        else if(notificationResponse.data.status_code == 408 || notificationResponse.data.status_code == 402){
          let obj = {
              "customer_id": localStorage.getItem("customer_id"),
              "deviceType": "web"
          }
          const logoutResponse = await dispatch(logoutApp(obj));
          if(logoutResponse.data.status_code == 200){
              
              localStorage.clear();
          
              history.push({pathname:  "/"})
              window.location.reload()
          }
      }
        else{
          setloader(false)
        }
        
    }

const orderdetailsview=async(orderId,notificationid,isreadstatus)=>{
    console.log(notificationid)
  window.scroll(0,0)
  if(orderId!=undefined && isreadstatus!=true){
    props.closemeetdesignerModal()
    console.log(orderId)
    let obj={
      "id":notificationid,
      "token": localStorage.getItem('user_token')
    }
  const updatenotificationstatusResponse = await dispatch(updatenotificationstatus(obj))
  console.log(updatenotificationstatusResponse)
    history.push('/orderdetails/'+orderId)
    gaEvent('notification_open', 'Launch Notification from website', 'Website Notifications');
    SMEvent(SMEvent_notification_open, No_Properties)
  }
  else{
    props.closemeetdesignerModal()
    history.push('/orderdetails/'+orderId)
    gaEvent('notification_open', 'Launch Notification from website', 'Website Notifications');
    SMEvent(SMEvent_notification_open, No_Properties)
  }
  }
return (
<React.Fragment>
{unreadcount?
<span className="unread_countnot">{Translation[details.Languages]["Unreadnotify"]} ({ unreadcount})</span>:""
}
{!loader?
  notificationData.length?notificationData.map(notify=>(
    notify.isRead == false ?
    <div className="row notification-tile tile_grey" onClick={notify.notification_type == "designer_assigned" ?() => props.setmeetdesignerModalPopUp(notify.order_id,notify._id,notify.isRead,"notification"): ()=>orderdetailsview(notify.order_id,notify._id,notify.isRead)}>
        <div className="col-2" style={{margin: "auto"}}>
          <img src={ProfileImagenot} alt="profile" />
        </div>
        <div className="col-8">
          <p className="notf-order-id">
          {notify.order_details[0] && notify.order_details[0].order_id? notify.order_details[0].order_id :""}
          </p>
          <p className="notf-contract-status">
            {details.Languages == "en" ? notify.notification_title : notify.notification_title_arabic}
          </p>
          <p className="notf-contract-description">
            {details.Languages == "en" ? notify.notification_content : notify.notification_content_arabic}
          </p>
        </div>
        <div className="col-2 notf-order-id">
            {moment(notify.created_on).format("MMM DD")}
        </div>
      </div>:
      <div className="row notification-tile" onClick={notify.notification_type == "designer_assigned" ?() => props.setmeetdesignerModalPopUp(notify.order_id,notify._id,notify.isRead,"notification"): ()=>orderdetailsview(notify.order_id,notify._id,notify.isRead)}>
      <div className="col-2" style={{margin: "auto"}}>
        <img src={ProfileImagenot} alt="profile" />
      </div>
      <div className="col-8">
        <p className="notf-order-id">
        {notify.order_details[0] && notify.order_details[0].order_id? notify.order_details[0].order_id :""}
        </p>
        <p className="notf-contract-status">
          {details.Languages == "en" ? notify.notification_title : notify.notification_title_arabic}
        </p>
        <p className="notf-contract-description">
          {details.Languages == "en" ? notify.notification_content : notify.notification_content_arabic}
        </p>
      </div>
      <div className="col-2 notf-order-id">
          {moment(notify.created_on).format("MMM DD")}
      </div>
    </div>
))
:
<div className="no-notification">
    {Translation[details.Languages]["NoNotification"]}
</div>
:
<div className="loader_center">
<ClipLoader color={'#242E49'} loading={loader} />
</div>
}
</React.Fragment>
);
}

export default Notification;