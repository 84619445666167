import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Radio } from "antd";
import Translation from "../../language/translate";
import { moreInformation } from "../../actions/bookFreeConsultationActions";
import { Select, Slider } from "antd";
import "./index.css";

const { Option } = Select;

const MoreInformation = (props) => {
  const details = useSelector((state) => state);

  const dispatch = useDispatch();

  const [sliderValue, setSliderValue] = useState(
    details.moreInformation.moreInformation.days
      ? details.moreInformation.moreInformation.days
      : props.orderData.project_start_expected_by?
      (props.orderData.project_start_expected_by).split(" ")[0] 
      : 0
  );

  const [priceRange, setPriceRange] = useState(
    details.moreInformation.moreInformation.priceRange
      ? details.moreInformation.moreInformation.priceRange
      : props.orderData.budget_range_type? props.orderData.budget_range_type : ""
  );

  const onChangeSite = (e) => {
    if(e == "New Site") {
        return "جديد"
    } else return "قديم"
};


const onChangeRange = (value) => {
    if(value == "Economical")
        return "اقتصادي"
        else if(value == "Moderate")
        return "متوسط "
        else if(value == "Premium")
        return "عالي "
        else return "أي ميزانية, (غير مقيد الميزانية)"
};

  const [priceRangeArabic, setPriceRangeArabic] = useState(
    details.moreInformation.moreInformation.priceRangeArabic
      ? details.moreInformation.moreInformation.priceRangeArabic
      : props.orderData.budget_range_type? onChangeRange(props.orderData.budget_range_type) : ""
  );

  const [choseContent, setChoseContent] = useState([]);

  const [mandatoryError, setMandatoryError] = useState(false);

  const [siteInfo, setSiteInfo] = useState(
    details.moreInformation.moreInformation.site
      ? details.moreInformation.moreInformation.site == "New Site" || details.moreInformation.moreInformation.site == "new_site"? "New Site" : "Old Site"
      : props.orderData.site_type? props.orderData.site_type == "New Site" || props.orderData.site_type == "new_site" ? "New Site" : "Old Site" : ""
  );

  const [siteInfoArabic, setSiteInfoArabic] = useState(
    details.moreInformation.moreInformation.siteInfoArabic
      ? details.moreInformation.moreInformation.siteInfoArabic
      : props.orderData.site_type? onChangeSite(props.orderData.site_type == "New Site" || props.orderData.site_type == "new_site"? "New Site" : "Old Site") : ""
  );

  const sliderValueFun = (value) => setSliderValue(value);

  function formatter(value) {
    sliderValueFun(value);
  }

  const onChangeRadio = (e) => {
    setSiteInfo(e.target.value == "New Site" || e.target.value == "new_site"? "New Site" : "Old Site");
    setSiteInfoArabic(e.target.value == "New Site" || e.target.value == "new_site"? "جديد" : "قديم");
  };
  const onChangeSelect = (value) => {
    setPriceRange(value);
    setPriceRangeArabic(
      value == "Economical"
        ? "اقتصادي"
        : value == "Moderate"
        ? "متوسط "
        : value == "Premium"
        ? "عالي "
        : "أي ميزانية, (غير مقيد الميزانية)"
    );
  };

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = () => {
    let choseContent = [
      details.designKitchen.kitchenSizeShape.shape? details.designKitchen.kitchenSizeShape.shape : props.orderData.kitchen_shape_title,
      details.designKitchen.kitchenSizeShape.size && details.designKitchen.kitchenSizeShape.sizeUnit? details.designKitchen.kitchenSizeShape.size +
        " " +
        details.designKitchen.kitchenSizeShape.sizeUnit +
        " (Kitchen size)" : props.orderData.kitchen_size + " " + props.orderData.kitchen_size_dimension +" " +"(Kitchen size)",
        details.chooseStyle.kitchenStyleSelected? details.chooseStyle.kitchenStyleSelected : props.orderData.kitchen_style_category_name,
    ];

    let choseContentArabic = [
      details.designKitchen.kitchenSizeShape.shapeArabic? details.designKitchen.kitchenSizeShape.shapeArabic : props.orderData.kitchen_shape_title_arabic,
      details.designKitchen.kitchenSizeShape.size && details.designKitchen.kitchenSizeShape.sizeUnit? details.designKitchen.kitchenSizeShape.size + " " + details.designKitchen.kitchenSizeShape.sizeUnit + " (مقاس المطبخ)" : props.orderData.kitchen_size + " " + props.orderData.kitchen_size_dimension +" " +"(مقاس المطبخ)",
      details.chooseStyle.kitchenStyleSelectedArabic? details.chooseStyle.kitchenStyleSelectedArabic : props.orderData.kitchen_style_category_name_arabic,
    ];

    setChoseContent(
      details.Languages == "en" ? choseContent : choseContentArabic
    );
  };

  const moreInfoContinueButton = () => {
    if (
      siteInfo === "" ||
      siteInfo === undefined ||
      priceRange === "" ||
      priceRange === undefined
    ) {
      setMandatoryError(true);
    } else {
      dispatch(
        moreInformation(
          siteInfo,
          priceRange,
          sliderValue,
          siteInfoArabic,
          priceRangeArabic
        )
      );
      setMandatoryError(false);
      props.handleNext();
    }
  };

  return (
    <React.Fragment>
      <div
        className="more-infornmation-wrap"
        align={details.Languages == "ar" ? "right" : ""}
      >
        <div className="header-title" style={{ paddingBottom: "5%" }}>
          {Translation[details.Languages]["MoreInformation"]}
        </div>
        {/* <div className="header-description">
            {Translation[details.Languages]["SelectBelow"]}
        </div> */}
        <div className="row">
          <div className="col-lg-8 col-md-12 col-sm-12">
            <div className="site-info-class">
              {details.Languages == "en" ? (
                <i
                  className="fa fa-long-arrow-right long-arrow-header"
                  aria-hidden="true"
                ></i>
              ) : (
                ""
              )}
              {Translation[details.Languages]["SiteInfo"]}
              <span className="starRed">*</span>
              {details.Languages == "ar" ? (
                <i
                  className="fa fa-long-arrow-left long-arrow-left-header"
                  aria-hidden="true"
                ></i>
              ) : (
                ""
              )}
            </div>
            <div style={{ padding: "15px 15px 50px 15px" }}>
              <Radio.Group
                onChange={onChangeRadio}
                value={siteInfo}
                size={"large"}
              >
                <Radio value={"New Site"} name="New" className="new-site">
                  {Translation[details.Languages]["NewSite"]}
                </Radio>
                <Radio value={"Old Site"} name="Old" className="old-site">
                  {Translation[details.Languages]["OldSite"]}
                </Radio>
              </Radio.Group>
            </div>
            <div className="site-info-class">
              {details.Languages == "en" ? (
                <i
                  className="fa fa-long-arrow-right long-arrow-header"
                  aria-hidden="true"
                ></i>
              ) : (
                ""
              )}
              {Translation[details.Languages]["BudgetRange"]}
              <span className="starRed">*</span>
              {details.Languages == "ar" ? (
                <i
                  className="fa fa-long-arrow-left long-arrow-left-header"
                  aria-hidden="true"
                ></i>
              ) : (
                ""
              )}
            </div>
            <div style={{ padding: "15px 0px 50px" }} className="txt_fieldresp">
              <Select
                style={{ width: 450 }}
                placeholder={Translation[details.Languages]["ChooseBudget"]}
                defaultValue={priceRange}
                optionFilterProp="children"
                size="large"
                className="budget-select"
                onChange={onChangeSelect}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="Economical">
                  <div>
                    <div
                      className={
                        details.Languages == "en" ? "ale_chana" : "ale_chanaAr"
                      }
                    >
                      {Translation[details.Languages]["EconomicalBudget"]}
                    </div>
                  </div>
                </Option>
                <Option value="Moderate">
                  <div>
                    <div
                      className={
                        details.Languages == "en" ? "ale_chana" : "ale_chanaAr"
                      }
                    >
                      {Translation[details.Languages]["BudgetFriendly"]}
                    </div>
                  </div>
                </Option>
                <Option value="Premium">
                  <div>
                    <div
                      className={
                        details.Languages == "en" ? "ale_chana" : "ale_chanaAr"
                      }
                    >
                      {Translation[details.Languages]["PremiumBudget"]}
                    </div>
                  </div>
                </Option>
                {/* <Option value="Any Budget (No Budget Constraint)">
                            <div>
                                <div style={{float: "left"}}>{Translation[details.Languages]["AnyBudget"]}</div>
                                <div style={{float: "right"}}>{Translation[details.Languages]["NoBudgetConstraint"]}</div>
                            </div>
                        </Option> */}
              </Select>
            </div>
            <div className="site-info-class">
              {details.Languages == "en" ? (
                <i
                  className="fa fa-long-arrow-right long-arrow-header"
                  aria-hidden="true"
                ></i>
              ) : (
                ""
              )}
              {Translation[details.Languages]["Estimation"]}
              <span className="starRed">*</span>
              {details.Languages == "ar" ? (
                <i
                  className="fa fa-long-arrow-left long-arrow-left-header"
                  aria-hidden="true"
                ></i>
              ) : (
                ""
              )}
            </div>
            <div style={{ padding: "15px 15px 50px 0px" }}>
              <div
                className={
                  details.Languages == "en"
                    ? "slider-div"
                    : "slider-div float-right-arabic"
                }
              >
                <Slider
                  tipFormatter={formatter}
                  min={4}
                  max={12}
                  className="kitchen-slider"
                  tooltipVisible={false}
                  defaultValue={sliderValue}
                />
              </div>
              <div
                className={
                  details.Languages == "en"
                    ? "sliver-value-div"
                    : "sliver-value-divar float-right-arabic"
                }
              >
                {sliderValue == 0
                  ? ""
                  : sliderValue == 1
                  ? `${sliderValue} month`
                  : `${sliderValue} months`}
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12">
            <div className="you-chose-card-info-screen">
              <div className="you-chose-title">
                {Translation[details.Languages]["YouChose"]}
              </div>
              <ul
                className={
                  details.Languages == "en"
                    ? "chose-info"
                    : "chose-info chose-info-arabic"
                }
              >
                {choseContent.map((list) => (
                  <li
                    className={
                      details.Languages == "ar" ? "list-padding-arabic" : ""
                    }
                  >
                    {details.Languages == "en" ? (
                      <i
                        className="fa fa-long-arrow-right long-arrow-class-more-info"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      ""
                    )}
                    {list}
                    {details.Languages == "ar" ? (
                      <i
                        className="fa fa-long-arrow-left long-arrow-left-class-more-info"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      ""
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div
            className={
              mandatoryError
                ? "row button-wrap button-wrap-padding-with-error"
                : "row button-wrap button-wrap-padding"
            }
          >
            <div className="col-lg-6 col-md-6 col-sm-12 go-back-wrap">
              <div
                className={
                  details.Languages == "en"
                    ? "go-back-button"
                    : "go-back-button float-left-arabic resp_gobkbtn"
                }
                onClick={props.handlePrevious}
              >
                {Translation[details.Languages]["GoBackButton"]}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 continue-wrap">
              <input
                class="continue-button"
                type="button"
                onClick={moreInfoContinueButton}
                // disabled={siteInfo == "" || siteInfo == undefined || priceRange == "" || priceRange == undefined ? true : false}
                value={Translation[details.Languages]["ContinueButton"]}
              />
            </div>
            {mandatoryError ? (
              <div className="mandatory-error">All fields are mandatory</div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MoreInformation;
