import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PhoneImage from "../../images/Phoneicon.png";
import IntlTelInput from "react-intl-tel-input";
import OtpInput from "react-otp-input";
import Translation from "../../language/translate";
import { ClipLoader } from "react-spinners";
import {
  getOtpOfUpdatedNumber,
  verifyotp,
} from "../../actions/editProfileDetailsAndNumberAction";
import { getallcountries, logoutApp } from "../../actions/loginactions";
import { useHistory } from "react-router";
import "react-intl-tel-input/dist/main.css";
import "./index.css";
import CountDown from "../atoms/countdown/CountDown";
import { arToNumbers } from "../../utility/format";

const ChangePhoneNumber = (props) => {
  const history = useHistory();
  const details = useSelector((state) => state);
  const dispatch = useDispatch();

  const [phonevalues, setgenerateotpmodule] = useState({
    error: "",
    validate_phone: false,
  });
  const [otpvalues, setverifyotpmodule] = useState({
    otperror: "",
  });
  const [showOtpInputBox, setShowOtpInputBox] = useState(false);
  const [allcountrieslist, setallcountrieslist] = useState([]);
  const [counterStoped, setCounterStoped] = useState(false);
  const [isLoading,setIsLoading]=useState(false);
  const [otpLoading,setOtpLoading]=useState(false);

  const onLoad = async () => {
    const countryresponse = await dispatch(getallcountries());
    if (countryresponse.data.status_code == 200) {
      setallcountrieslist(
        countryresponse.data.configuration_list.map(
          (allList) => allList.country_code
        )
      );
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const typemobilenumber = (isValid, input, countryData,fullNumber) => {
    const normalizedInput = arToNumbers(input);
    var references = countryData;
    var countryCode = references.dialCode;
    var countryName = references.name;
    var phoneNoWithoutAlpha = normalizedInput.replace(/[^0-9.]/g, "");
    if (isValid == true && normalizedInput != "") {
      setgenerateotpmodule({
        phone: ` ${phoneNoWithoutAlpha}`,
        countryCode: `+${countryCode}`,
        countryName: countryName,
        displayPhone: "none",
        error: "",
        validate_phone: true,
      });
    } else {
      setgenerateotpmodule({
        phone: `${phoneNoWithoutAlpha}`,
        displayPhone: "block",
        error: "Please enter the valid phone number",
        validate_phone: false,
      });
    }
  };

  const getOtp = async () => {
    let obj = {
      old_mobile_number: localStorage.getItem("mobile_no"),
      mobile_number: phonevalues.phone.trim(),
      country_code: phonevalues.countryCode,
      userType: "customer",
      token: localStorage.getItem("user_token"),
    };
    setIsLoading(true)
    const getOtpOfUpdatedNumberResponse = await dispatch(
      getOtpOfUpdatedNumber(obj)
    );
    if (getOtpOfUpdatedNumberResponse.data.status_code == 200) {
      setShowOtpInputBox(true);
      setIsLoading(false)
    } else if (getOtpOfUpdatedNumberResponse.data.status_code == 403) {
      // setShowOtpInputBox(false)
      setIsLoading(false)
      setgenerateotpmodule({
        phone: phonevalues.phone,
        countryCode: phonevalues.countryCode,
        error: "Number already registered",
      });
    } else if (
      getOtpOfUpdatedNumberResponse.data.status_code == 408 ||
      getOtpOfUpdatedNumberResponse.data.status_code == 402
    ) {
      let obj = {
        customer_id: localStorage.getItem("customer_id"),
        deviceType: "web",
      };
      const logoutResponse = await dispatch(logoutApp(obj));
      if (logoutResponse.data.status_code == 200) {
        localStorage.clear();

        history.push({ pathname: "/" });
        window.location.reload();
      }
    }
  };

  const fetchallotpvalues = async () => {
    if (otpvalues.otpinputvalues == undefined || otpvalues.otplength < 6) {
      setverifyotpmodule({
        otperror: "Please enter all the values",
      });
    } else {
      setOtpLoading(true)
      let obj = {
        old_mobile_number: localStorage.getItem("mobile_no"),
        mobile_number: phonevalues.phone.trim(),
        mobileOTP: otpvalues.otpinputvalues,
        deviceType: "web",
        token: localStorage.getItem("user_token"),
      };
      const verifyOtpResponse = await dispatch(verifyotp(obj));
      if (verifyOtpResponse.data.status_code == 200) {
        setOtpLoading(false)
        localStorage.setItem(
          "mobile_no",
          verifyOtpResponse.data.details.mobile_number
        );
        localStorage.setItem(
          "country_code",
          verifyOtpResponse.data.details.country_code
        );
        localStorage.removeItem("utm_source");
        localStorage.removeItem("utm_medium");
        localStorage.removeItem("utm_campaign");
        localStorage.removeItem("utm_term");
        localStorage.removeItem("utm_content");
        props.handleClose();
      } else if (verifyOtpResponse.data.status_code == 406) {
        setOtpLoading(false)
        setverifyotpmodule({
          otperror: "Invalid otp or mobile number",
        });
      } else if (
        verifyOtpResponse.data.status_code == 408 ||
        verifyOtpResponse.data.status_code == 402
      ) {
        let obj = {
          customer_id: localStorage.getItem("customer_id"),
          deviceType: "web",
        };
        const logoutResponse = await dispatch(logoutApp(obj));
        if (logoutResponse.data.status_code == 200) {
          localStorage.clear();

          history.push({ pathname: "/" });
          window.location.reload();
        }
      }
    }
  };

  const triggerOtp = (e) => {
    const normalizedInput = arToNumbers(e);
    setverifyotpmodule({
      otpinputvalues: normalizedInput,
      otplength: normalizedInput.length,
    });
  };

  const handleClose = () => {
    props.handleClose();
    setShowOtpInputBox(false);
  };
  return (
    <React.Fragment>
      <div align={details.Languages == "ar" ? "right" : ""}>
        <div className="row welcome-div">
          <div className="col-2 editprf_resp" style={{ margin: "auto" }}>
            <img src={PhoneImage} alt="" />
          </div>
          <div className="col-10 prfdet_resp">
            <div className="welcome-name">
              {Translation[details.Languages]["ChangeNumber"]}
            </div>
            <div className="welcome-text">
              {Translation[details.Languages]["PhoneAlert"]}
            </div>
          </div>
        </div>
        {!showOtpInputBox ? (
          <div style={{ padding: "5% 10%" }}>
            <div className="mobile-heading">
              {Translation[details.Languages]["EnterMobileNumber"]}
            </div>
            <div className="inputfield">
              <span className="tep_inputfield" dir="ltr">
                {allcountrieslist.length > 0 ? (
                  <IntlTelInput
                    fieldId="phone"
                    defaultCountry="sa"
                    onlyCountries={allcountrieslist}
                    value={phonevalues.phone}
                    preferredCountries={[]}
                    containerClassName="intl-tel-input"
                    inputClassName={
                      details.Languages == "en"
                        ? "form-control tel_appnuii"
                        : "form-control tel_appnuiiarabic"
                    }
                    placeholder={
                      Translation[details.Languages]["mobilenochange"]
                    }
                    onPhoneNumberChange={typemobilenumber}
                    onPhoneNumberBlur={typemobilenumber}
                  />
                ) : (
                  <></>
                )}
              </span>
              <div
                className="phoneerror text-danger"
                style={{
                  textAlign: "left",
                  paddingBottom: "2%",
                  width: "100%",
                  float: "left",
                }}
              >
                {phonevalues.error}
              </div>
            </div>
           
              <div className="row button-wrap-div">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="cancel-button" onClick={() => handleClose()}>
                    {Translation[details.Languages]["Cancel"]}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                {
                isLoading ?
                  <div className="loader_center"><ClipLoader  color={'#242E49'}   loading={isLoading}  /></div>
                  :
                  <input
                    className="getotp-button"
                    type="button"
                    value={Translation[details.Languages]["GetOtp"]}
                    onClick={() => getOtp()}
                    disabled={!phonevalues.validate_phone}
                  />
                }
                </div>
              </div>
              <div
                className="otp_txtapp fix_otp"
                style={
                  details.Languages == "en"
                    ? { textAlign: "left" }
                    : { textAlign: "right" }
                }
              >
                {Translation[details.Languages]["SendingOtp"]}
              </div>
              
          </div>
        ) : (
          <div className="row">
            <div className="mt-5">
              <div className="mobile-heading" style={{ margin: "0" }}>
                Verification
              </div>
              <div
                className="otp_txtapp"
                style={
                  details.Languages == "en"
                    ? { textAlign: "left" }
                    : { textAlign: "right" }
                }
              >
                {Translation[details.Languages]["OtpNumber"]}
              </div>
              <div className="app_std">
                <div className="phn_appwy" style={{ textAlign: "left" }}>
                  {phonevalues.countryCode}
                  {phonevalues.phone}
                </div>
                <div
                  className="phn_appwychange send-otp-text"
                  onClick={() => setShowOtpInputBox(false)}
                >
                  {Translation[details.Languages]["ChangePhoneNumber"]}
                </div>
              </div>
              <div className="app_std">
                <div className="otp_text_field-change">
                  <OtpInput
                    onChange={triggerOtp}
                    numInputs={6}
                    name="otp"
                    value={otpvalues.otpinputvalues}
                    inputStyle="change_otp_fld_niu"
                    // containerStyle="inputTextOtp"
                    focusStyle="focusOtpField"
                    separator={<div> </div>}
                  />
                </div>
                <div className="app_std">
                  <div className="row otp-button-wrap-div">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <div
                        className="cancel-button"
                        onClick={() => handleClose()}
                      >
                        {Translation[details.Languages]["Cancel"]}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      {
                        otpLoading ?
                          <div className="loader_center"><ClipLoader  color={'#242E49'}   loading={otpLoading}  /></div>
                        :
                        <input
                          className="getotp-button"
                          type="button"
                          value="VERIFY"
                          onClick={fetchallotpvalues}
                        />
                      }
                    </div>
                  </div>
                  <div
                    className="phoneerror text-danger"
                    style={{ textAlign: "left", margin: "10px 0" }}
                  >
                    {otpvalues.otperror}
                  </div>
                  {/* <div className="phoneerror">{props.otpvalueserror}</div>
                <div className="phoneerrorsucess">{props.otpsuccessmsg}</div> */}
                  {counterStoped ? (
                    <span
                      className="otp_txtappresend send-otp-text"
                      onClick={() => {
                        getOtp();
                        setCounterStoped(false);
                      }}
                    >
                      {Translation[details.Languages]["ResendOTP"]}
                    </span>
                  ) : (
                    <div className="otp_txtappresend" style={{ width: "100%" }}>
                      <span>
                        {Translation[details.Languages]["TimerReq"]}{" "}
                        <CountDown
                          CountDownStart={30}
                          setCounterStoped={setCounterStoped}
                        />
                        {Translation[details.Languages]["TimerReqsec"]}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ChangePhoneNumber;
