import smartlookClient from "smartlook-client"

//calling native module function
export function SMEvent(event, properties, valueProperties) {
    let propertiesValue
    if(properties == 'SMEvent_P_Add_payment_info' && valueProperties){
        propertiesValue = {'payment_info' : valueProperties} 
    } else {
        propertiesValue = {"type": properties} 
    }
    smartlookClient.track(event, propertiesValue);
}

// events
export const SMEvent_Booked_A_Demo = 'SMEvent_Booked_A_Demo';
export const SMEvent_designer_profile_viewed = 'SMEvent_designer_profile_viewed'
export const SMEvent_notification_open = 'SMEvent_notification_open'
export const SMEvent_get_your_kitchen = 'SMEvent_get_your_kitchen'
export const SMEvent_Book_consultation = 'SMEvent_Book_consultation';
export const SMEvent_Contract_PDF = 'SMEvent_Contract_PDF';
export const SMEvent_Comfort = 'SMEvent_Comfort';
export const SMEvent_Urban = 'SMEvent_Urban';
export const SMEvent_Neat = 'SMEvent_Neat';
export const SMEvent_Classic = 'SMEvent_Classic';
export const SMEvent_Payment = 'SMEvent_Payment';
export const SMEvent_Get_Started_FloatingBtn = 'SMEvent_P_Get_Started_FloatingBtn'
export const SMEvent_View_Catalog = 'SMEvent_View_Catalog';
export const SMEvent_FAQ = 'SMEvent_FAQ';
export const SMEvent_TermsConditions = 'SMEvent_Terms&Conditions';
export const SMEvent_Privacy_Policy = 'SMEvent_Privacy_Policy';
export const SMEvent_Logout = 'SMEvent_Logout';
export const SMEvent_Login = 'SMEvent_Login';
export const SMEvent_Register = 'SMEvent_Register';
export const SMEvent_Booking = 'SMEvent_Booking';
export const SMEvent_Category = 'SMEvent_Category';


//properties
export const SMEvent_P_Booking_started = 'SMEvent_P_Booking_started';
export const SMEvent_P_Designer_profile = 'SMEvent_P_Designer_profile';
export const SMEvent_P_Kitchen_Detail_Page = 'SMEvent_P_Kitchen_Detail_Page';
export const SMEvent_P_Contract_PDF_View = 'SMEvent_P_Contract_PDF_View';
export const SMEvent_P_Payment_failure = 'SMEvent_P_Payment_failure';
export const SMEvent_P_Login_started = 'SMEvent_P_Login_started';
export const SMEvent_P_Login_completed = 'SMEvent_P_Login_completed';
export const SMEvent_P_Registration_Started = 'SMEvent_P_Registration_Started';
export const SMEvent_P_Registration_completed ='SMEvent_P_Registration_completed';
export const SMEvent_P_Payment_success = 'SMEvent_P_Payment_success';
export const SMEvent_P_Add_payment_info = 'SMEvent_P_Add_payment_info';
export const SMEvent_P_Booking_completed = 'SMEvent_P_Booking_completed';

//other_Supporting_values
export const During_Logout = 'During_Logout';
export const No_Properties = 'no_property';