import GA4React from 'ga-4-react';

let GA;
const ga4react = new GA4React('G-G0CXZ3ZS8K',10000);
ga4react.initialize().then((ga4) => {
  GA = ga4;
  console.log("GA_INITIALIZED")
},(err) => {
  console.error("GA_INITIALIZE_FAILED",err)
})

const initializeGAOnFailure = function () {
  console.log('GA_INITAILIZING_AGAIN')
  ga4react.initialize().then((ga4) => {
    GA = ga4;
    console.log("GA_INITIALIZED")
  },(err) => {
    console.error("GA_INITIALIZE_FAILED",err)
  })
}


//PAGE_VIEW
export const gaPageView = (path, location, title) => {
  if (GA == undefined) {
    setTimeout(function () {
      if (GA != undefined) {
        GA.pageview(path, location, title);
        console.log("PAGE_VIEW_RUN_TIMEOUT");
      } else {
        initializeGAOnFailure()
      }
    }, 10000)
  } else {
    GA.pageview(path, location, title);
    console.log("PAGE_VIEW_RUN_NORMAL");
  }
};

//EVENT
export const gaEvent= (action, label, category) => {
  if (GA == undefined) {
    setTimeout(function () {
      if (GA != undefined) {
        //action,label & category
        //eventAction => The type of interaction (e.g. 'play')
        //eventLabel => Useful for categorizing events (e.g. 'Fall Campaign')
        //eventCategory =>Typically the object that was interacted with (e.g. 'Video')
        GA.event(action, label, category);
        console.log("EVENT_RUN_TIMEOUT");
      } else {
        initializeGAOnFailure()
      }
    }, 10000)
  } else {
    GA.event(action, label, category);
    console.log("EVENT_RUN_NORMAL");
  }
};

//gTag EVENT
export const gtagEvent= (event,pageview,path) => {
  if (GA == undefined) {
    setTimeout(function () {
      if (GA != undefined) {
        GA.gtag(event,pageview,path);
        console.log("EVENT_RUN_TIMEOUT");
      } else {
        initializeGAOnFailure()
      }
    }, 10000)
  } else {
    GA.gtag(event,pageview,path);
    console.log("EVENT_RUN_NORMAL");
    console.log(event)
    console.log(pageview)
    console.log(path)
  }
};