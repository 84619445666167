let InitialStateValue = localStorage.getItem('language') ? localStorage.getItem('language') : 'ar'


const languages = (state = InitialStateValue, action) => {
    switch (action.type) {
      case 'CHANGE_LANGUAGE':
        return action.language;
      default:
        return state;
    }
  };

export default languages