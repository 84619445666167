const fakeAuth = {
    isAuthenticated: localStorage.getItem('user_token') === ''  ?false:true,
    authenticate(token,id,grade,name,cb) {
        localStorage.setItem('user_token',token);
        this.isAuthenticated = true
        setTimeout(cb, 100) // fake async
    },
    signout(cb) {
      this.isAuthenticated = false
     
      setTimeout(cb, 100) // fake async
    }
  }
  
  export default fakeAuth;
  