import React,{useState, useEffect} from 'react';
import { useSelector, useDispatch} from "react-redux";
import Translation from "../../language/translate"
import { ClipLoader } from 'react-spinners';
import {getKitchenShape, designKitchen, fnGetDimensions, NonKitchen, getAllCategories} from "../../actions/bookFreeConsultationActions"
import { getallcustomerorderslist } from "../../actions/orderlistingactions";
import Tick from "../../images/tick.png"
import {logoutApp} from "../../actions/loginactions";
import { useHistory } from "react-router";
import { Select, Slider } from 'antd';
import "./index.css"

const { Option } = Select;

const NonKitchenInfo=(props)=>{

const history = useHistory();

const details = useSelector(state => state);

const dispatch = useDispatch();

const [sliderValue, setSliderValue]=useState(details.designKitchen.kitchenSizeShape.size? details.designKitchen.kitchenSizeShape.size: props.orderData.kitchen_size? Number(props.orderData.kitchen_size) : 0)

const [kitchenOrder, setKitchenOrder] = useState(details.nonKitchen.nonkitchen.kitchenOrder ? details.nonKitchen.nonkitchen.kitchenOrder : props.orderData.kitchen_shape_title? props.orderData.kitchen_shape_title : "")

const [kitchenOrderArabic, setKitchenOrderArabic] = useState()

const [loader, setloader]=useState(false)

const [orderListLoader,setOrderListLoader]=useState(true);

const [kitchenShapes, setKitchenShapes] = useState([])

// const [kitchenShapes, setKitchenShapes] = useState([{_id: 1, title: "Kitchen Replacement", title_arabic: "Kitchen Replacement", label: "Kitchen Replacement"}, {_id: 2, title: "Accessories", title_arabic: "Accessories", label: "Accessories"}, {_id: 3, title: "Appliances", title_arabic: "Appliances", label: "Appliances"}, {_id: 4, title: "Worktops", title_arabic: "Worktop", label: "Worktop"}, {_id: 5, title: "Services", title_arabic: "Services", label: "Services"}, {_id: 6, title: "Spareparts", title_arabic: "Spareparts", label: "Spareparts"}])

const [kitchenOrdersList, setKitchenOrdersList] = useState([])

const [dimensions, setDimensions] = useState([])

const [mandatoryError, setMandatoryError] = useState(false)

const [shapeId, setShapeId] =useState(details.nonKitchen.nonkitchen.serviceId ? details.nonKitchen.nonkitchen.serviceId : props.orderData.kitchen_shape
    ? props.orderData.kitchen_shape : "")

const [serviceSelected, setServiceSelected] = useState(details.nonKitchen.nonkitchen.service ? details.nonKitchen.nonkitchen.service : props.orderData.kitchen_shape_title? props.orderData.kitchen_shape_title : "")

const [serviceCode, setServiceCode] =useState(details.nonKitchen.nonkitchen.serviceCode ? details.nonKitchen.nonkitchen.serviceCode : props.orderData.kitchen_shape_title
  ? props.orderData.kitchen_shape_title : "")

const [serviceSelectedArabic, setServiceSelectedArabic] = useState(details.nonKitchen.nonkitchen.serviceArabic ? details.nonKitchen.nonkitchen.serviceArabic : props.orderData.kitchen_shape_title_arabic? props.orderData.kitchen_shape_title_arabic : "")

const [sizeUnit, setSizeUnit] = useState(details.designKitchen.kitchenSizeShape.sizeUnit !== undefined ? details.designKitchen.kitchenSizeShape.sizeUnit : props.orderData.kitchen_size_dimension? props.orderData.kitchen_size_dimension : "Meter")

const sliderValueFun = (value) => setSliderValue(value);

function formatter(value) {
sliderValueFun(`${value}`)
}

useEffect(() => {
setloader(true)
onLoad()
}, []);

const onLoad = async () => {
  setloader(true)

  let categoryData = await dispatch(getAllCategories());
  // console.log("all categories in the pop up :", categoryData)

  if(categoryData.data.status_code == 200){
    setloader(false)
    const newCategoryData = categoryData.data.list.filter((data) => data.status == "Active" &&
    data.is_deleted == false && data.is_kitchen == false && data.is_visible == true && (data.code==="appliances" || data.code==="worktops"))
    setKitchenShapes(newCategoryData)
    // console.log("new caregory data :", newCategoryData)
  } 
  else if(categoryData.data.status_code == 408 || categoryData.data.status_code == 402){
    let obj = {
        "customer_id": localStorage.getItem("customer_id"),
        "deviceType": "web"
    }
    const logoutResponse = await dispatch(logoutApp(obj));
    if(logoutResponse.data.status_code == 200){
        
        localStorage.clear();
    
        history.push({pathname:  "/"})
        window.location.reload()
    }
}
else{
setloader(false)
}

  let odervalues = {"customer_id":localStorage.getItem("customer_id"),"token":localStorage.getItem("user_token")}
  setOrderListLoader(true)
  const oderlist= await dispatch(getallcustomerorderslist(odervalues))
  //console.log(oderlist)
  if(oderlist.data.status_code ==200){
    console.log(oderlist.data.order_list,"service selected")
    const newKitchenOrderListData = 
    oderlist.data.order_list.filter((data) =>
    data.creation_platform==="online" && 
    (data.order_type == "kitchen" ) &&
    (data.designers_details.length===0 || data.designers_details[0].work_platform==="online") && 
    (!data.is_order_lost || data.is_order_lost != true) &&
    (data.order_checklist_status_number > 1 || Object.keys(data.repeat_order).length != 0))
    let sortedList=newKitchenOrderListData.sort((a, b) => {
      if (+a.order_id < +b.order_id) {
        return 1;
      }
      if (+a.order_id > +b.order_id) {
        return -1;
      }
    });
    setKitchenOrdersList(sortedList)
    setloader(false)
    setOrderListLoader(false)
    // showLoader:false
  } else if(categoryData.data.status_code == 408 || categoryData.data.status_code == 402){
    let obj = {
        "customer_id": localStorage.getItem("customer_id"),
        "deviceType": "web"
    }
    const logoutResponse = await dispatch(logoutApp(obj));
    if(logoutResponse.data.status_code == 200){
        
        localStorage.clear();
    
        history.push({pathname:  "/"})
        window.location.reload()
    }
  } else{
  setloader(false)
  setOrderListLoader(false)
  }
}

const selectShape =(serviceType, serviceTypeArabic, serviceId, serviceCode)=>{
  setServiceSelectedArabic(serviceTypeArabic)
  setServiceSelected(serviceType)
  setShapeId(serviceId)
  setServiceCode(serviceCode)
}

const continueButtonClick=()=>{
  if(Number(serviceSelected.length) > 0 && serviceSelected != undefined || serviceSelected != ""){
    if(kitchenOrdersList.length===0){
      return setMandatoryError(true)
    }
    if(kitchenOrder===""){
      return setMandatoryError(true)
      // setKitchenOrder({ label:kitchenOrdersList[0].order_id, 
      //   value: [ kitchenOrdersList[0].order_id,kitchenOrdersList[0]._id, kitchenOrdersList[0].designers_details[0].referal_code ] })
    }
    dispatch(NonKitchen(serviceSelected, serviceSelectedArabic, kitchenOrder, shapeId, serviceCode))
    setMandatoryError(false)
    props.handleNext()
  } else {
    setMandatoryError(true)
  }
}

const onChangeSelect = (value) => {
    localStorage.setItem("parent_Kitchen_order", value[0])
    setKitchenOrder(value);
  };

// console.log("props.orderData :", props.orderData, "details.designKitchen :", details.designKitchen, "kitchenOrders :", kitchenOrdersList)
// console.log(kitchenOrdersList.length!==0?kitchenOrdersList[0].order_id:"","Sdasdas")
return (
<React.Fragment>
    {/* {console.log(sliderValue)} */}
    <div className={mandatoryError ? "design-non-kitchen design-non-kitchen-with-error-padding": "design-non-kitchen design-non-kitchen-padding"} align={details.Languages =="ar"?"right": ""}>
        <div className="header-title non-kitchen-title">
            {Translation[details.Languages]["bookingOtherOrderMoreInfo"]}
        </div>
        <div className="header-description non-kitchen-title">
            {Translation[details.Languages]["bookingOtherOrderServiceSelect"]}
        </div>
        {
          (loader || orderListLoader) ?
            <div className="loader_center order_list_loader">
                  <ClipLoader color={'#242E49'} loading={loader || orderListLoader} />
            </div>
          :
          <>
        <div className="row">
            {(!loader && !orderListLoader ) ?
            kitchenShapes.map(shape =>(

            <div className="col-lg-6 col-md-12 col-sm-12" onClick={()=> selectShape(shape.name, shape.name, shape._id, shape.code)}> 
                {serviceSelected == shape.name?
                <div className={details.Languages == "en"?"tick-div-class": "tick-div-class tick-div-class-arabic"}>
                    <img src={Tick} alt="tick" />
                </div>
                :""
                }
                <div className="kitchen-shape" >
                    <span className="lazyload non-kitchen-shape-title">
                    {/* <div className="kitchen-shape-title"> */}
                    {details.Languages == "en" ? shape.name : shape.name_ar}
                    {/* </div> */}
                    </span>
                    {/* <img data-src={shape.kitchen_shape_image} alt={shape.kitchen_shape_image} className="lazyload shape-image-div"/> */}
                </div>
                {/* <div className="kitchen-shape-title">
                    {details.Languages == "en" ? shape.title : shape.title_arabic}
                </div> */}
            </div>
            ))

            :
            <div className="loader_center">
                <ClipLoader color={'#242E49'} loading={loader || orderListLoader} />
            </div>
            }

            <div className='col-lg-12 col-md-12 col-sm-12'>
            <div className="header-description non-kitchen-title">
              {Translation[details.Languages]["bookingOtherOrderKitchenOrder"]}
        </div>

        <div style={{ padding: "15px 0px 50px" }} className="txt_fieldresp non-kitchen-title">
              <Select
                style={{ width: 640 }}
                placeholder={Translation[details.Languages]["bookingOtherOrderOption"]}
                // defaultValue={kitchenOrdersList.length?kitchenOrdersList[0].order_id:""}
                // defaultValue={kitchenOrdersList.length!==0?kitchenOrdersList[0].order_id:""}
                // defaultValue={localStorage.getItem("parent_Kitchen_order")? localStorage.getItem("parent_Kitchen_order") : "Select an option"}
                optionFilterProp="children"
                size="large"
                className="budget-select"
                onChange={onChangeSelect}
                value={kitchenOrder===""?Translation[details.Languages]["bookingOtherOrderOption"]:kitchenOrder}
                options = {
                  kitchenOrdersList.map((province) => (
                    { 
                      label: province.order_id, 
                      value: [ province.order_id,province._id, province.designers_details[0].referal_code ] 
                    }
                    ))
                }
                filterOption={(input, option) =>
                  option.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                key={kitchenOrdersList}
              >
              </Select>
            </div>
            </div>

            <div className="row continue-button-wrap">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  {
                    (!loader && !orderListLoader ) ?
                    <input class="continue-button"
                    type="button"
                    onClick={continueButtonClick}
                    // disabled={sliderValue == 0 || serviceSelected == ""? true : false}
                    value={Translation[details.Languages]["ContinueButton"]}/>
                    :
                    <div className="loader_center">
                      <ClipLoader color={'#242E49'} loading={loader || orderListLoader} />
                    </div>
                  }
                </div>
                {mandatoryError?
                <div className="mandatory-error">
                    {/* All fields are mandatory */}
                    {/* Please select a service */}
                    Fill All Fields
                </div>
                :""
                }
            </div>
        </div>
        </>
        }
    </div>
</React.Fragment>
);
}

export default NonKitchenInfo;