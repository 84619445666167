/* eslint-disable */

const initialState = {
    loginvalues: {},
    verifyotpvalues:{},
    showLoader: false,
    kitchendetails:[],
    kitchendetailscount:[]
  };
  
  const login = (state = initialState, action) => {
    console.log(action, "actions");
    switch (action.type) {
      case "LOGIN_SUCCESS":
        return {...state,loginvalues: action.success.data,showLoader: false,};
  
      case "LOGIN_FAILURE":
        return { ...state, showLoader: false, loginvalues: {} };
  
      case "LOGIN_REQUESTING":
        return { ...state, showLoader: true, loginvalues: {} };

      case "VERIFYOTP_SUCCESS":
        return {...state,verifyotpvalues: action.success.data,showLoader: false,};
  
      case "VERIFYOTP_FAILURE":
        return { ...state, showLoader: false, verifyotpvalues: {} };
  
      case "VERIFYOTP_REQUESTING":
        return { ...state, showLoader: true, verifyotpvalues: {} };

      case "CREATEACCOUNT_SUCCESS":
        return {...state,showLoader: false,};
  
      case "CREATEACCOUNT_FAILURE":
        return { ...state, showLoader: false,};
  
      case "CREATEACCOUNT_REQUESTING":
        return { ...state, showLoader: true, };

      default:
        return state;
    }
  };
  
  export default login;
  