import axios from "axios";
import config from "../config/properties";


export const getKitchenShape = () => {
    return async () => {
        try {
            const getKitchenShapeRequest = await axios.get(config.base_url + "customer/listAllActiveKitchenShapeCustomer")
                return getKitchenShapeRequest;
        } catch (e) {}
    }
}

export const fnGetDimensions = () => {
  return async () => {
      try {
          const getDimensionsRequest = await axios.get(config.base_url + "customer/listAllDimension")
              return getDimensionsRequest;
      } catch (e) {}
  }
}

export const fileUpload= (params)=>
{
  
    return async ()=>{
      let uploadedUrl="";
      // let imgexte = params.file.name.substr(params.file.name.lastIndexOf('.') + 1);
        try{
            const ImageUploadRequest=await axios.post(config.base_url + "customer/uploadProfilePic",
            {
              // "folder": "timelyimageupload",
              "file": params.file.name,
              "token": params.token
              //  "contentType": "application/"+imgexte
          },
         )
         
          if(ImageUploadRequest.data.status_code == 200){
              var formData = new FormData();
              for (let key in ImageUploadRequest.data.response.fields) {
                formData.append(key, ImageUploadRequest.data.response.fields[key]);
              }

                formData.append("file", params.file);
                
                await axios.post(ImageUploadRequest.data.response.url, formData);
              console.log(ImageUploadRequest.data.response.url + '/' + ImageUploadRequest.data.response.fields.key, "ImageUploadRequest")
  
              uploadedUrl = ImageUploadRequest.data.response.url + '/' + ImageUploadRequest.data.response.fields.key;
   
                return uploadedUrl
                  }
                }
        catch(e)
        {}
    }
}

export const getKitchenStyle = () => {
    return async () => {
        try {
            const getKitchenStyleRequest = await axios.get(config.base_url + "customer/homeScreenKitchenCategoryListCustomerWeb")
                return getKitchenStyleRequest;
        } catch (e) {}
    }
}

export const bookADemo = (param) => {
    return async () => {
        try {
            const bookDemoRequest = await axios.post(config.base_url + "customer/bookADemo", param,
            {headers: {
                'Content-Type': 'application/json',
        }})
            return bookDemoRequest;
            } 
            catch (e) {}
    }
}

export const bookADemoRepeatOrder = (param) => {
  return async () => {
      try {
          const bookDemoRepeatRequest = await axios.post(config.base_url + "customer/repeatOrder", param,
          {headers: {
              'Content-Type': 'application/json',
      }})
          return bookDemoRepeatRequest;
          } 
          catch (e) {}
  }
}

export const bookADemoRepeatAction = (param) => {
  return async () => {
      try {
          const bookADemoRepeatRequest = await axios.post(config.base_url + "customer/bookADemoRepeat", param,
          {headers: {
              'Content-Type': 'application/json',
      }})
          return bookADemoRepeatRequest;
          } 
          catch (e) {}
  }
}

export const designKitchen = (size, shape, shapeArabic, sizeUnit, shapeId) => {
    console.log(size, shape,sizeUnit)
    return async dispatch => {
        dispatch({
          type: "KITCHEN_SIZE_SHAPE",
          data: {size, shape, shapeArabic, sizeUnit: sizeUnit, shapeId: shapeId},
          })
        }
}


export const chooseKitchenStyleFun = (style, styleArabic, styleId) => {
    return async dispatch => {
        dispatch({
          type: "CHOOSE_KITCHEN_STYLE",
          data: {style: style, styleArabic: styleArabic, styleId: styleId},
          })
        }
}

export const moreInformation = (site, priceRange, days, siteInfoArabic, priceRangeArabic) => {
    return async dispatch => {
        dispatch({
          type: "DEMO_MORE_INFORMATION", 
          data: {site: site, priceRange: priceRange, days:days, siteInfoArabic: siteInfoArabic, priceRangeArabic: priceRangeArabic},
          })
        }
}


export const meetingDateAndTime = (date, time) => {
    return async dispatch => {
        dispatch({
          type: "MEET_DATE_TIME",
          data: {date: date, time: time},
          })
        }
}

// Promo code status check during order booking
export const promoCodeReferrerStatus = (param) => {
  return async () => {
    try {
      const ReferrerStatusRequest = await axios.post(config.base_url + "customer/getReferrerStatus", param,
      {headers: {
        'Content-Type': 'application/json',
      }})
      return ReferrerStatusRequest;
    } 
    catch (e) {}
  }
}

// 
export const NonKitchen = (service, serviceArabic, kitchenOrder, serviceId, serviceCode) => {
  console.log(service, serviceArabic, kitchenOrder, serviceId, serviceCode)
  return async dispatch => {
      dispatch({
        type: "NON_KITCHEN_DATA",
        data: {service, serviceArabic, kitchenOrder: kitchenOrder, serviceId: serviceId, serviceCode: serviceCode},
        })
      }
}

// get all categories for the Non-Kitchen booking

export const getAllCategories = () => {
  return async () => {
    try {
      const categoriesData = await axios.post(config.base_url + "admin/getAllCategory",
      {headers: {
        'Content-Type': 'application/json',
      }})
      return categoriesData;
    }
    catch (e) {}
  }
}

// Non-Kitchen booking

export const NonKitchenBooking = (param) => {
  return async () => {
    try {
      const bookNonKitchen = await axios.post(config.base_url + "customer/bookNonKitchenAppointment", param, {headers: { 'Content-Type': 'application/json'}})
      return bookNonKitchen;
    }
    catch (e) {}
  }
}