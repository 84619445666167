import { createStore,applyMiddleware,compose} from "redux";
import thunk from 'redux-thunk'
import rootReducer from "./reducers/rootReducer";

const middlewares = [thunk]
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// const composedEnhancers = compose(
//         applyMiddleware(...middleware),
//         ...enhancers
// )
      
  
const store = createStore(rootReducer,{},composeEnhancers(applyMiddleware(...middlewares)));

export default store;