import axios from "axios";
import config from "../config/properties";

export const headermenuoptions=()=>
{
  return async (dispatch)=>{
    const headervalues=await axios.post(config.base_url+'customer/homeScreenKitchenCategoryListCustomerWeb',)
    return headervalues;
  }
}

