/* eslint-disable */

const initialState = {
    orderdetailvalues:[],
    showLoader: false,
  };
  
  const orderdetails = (state = initialState, action) => {
    console.log(action, "actions");
    switch (action.type) {
      case "ORDERDETAIL_SUCCESS":
        return {...state,orderdetailvalues: action.success.data.order_details[0],showLoader: false,};
  
      case "ORDERDETAIL_FAILURE":
        return { ...state, showLoader: false, orderdetailvalues: [] };
  
      case "ORDERDETAIL_REQUESTING":
        return { ...state, showLoader: true, orderdetailvalues: [] };

      default:
        return state;
    }
  };
  
  export default orderdetails;
  