import axios from "axios";
import config from "../config/properties";


export const getDesignerDetails = (obj) => {
    return async () => {
        try {
            const getDesignerDetailsRequest = await axios.post(config.base_url + "customer/getCustomerOrderDetails", obj)
                return getDesignerDetailsRequest;
        } catch (e) {}
    }
}

export const getDesignerDetailsById = (obj) => {
    return async () => {
        try {
            const getDesignerDetailsByIdRequest = await axios.post(config.base_url + "customer/getDesignerProfileDetails", obj)
                return getDesignerDetailsByIdRequest;
        } catch (e) {}
    }
}

export const getDeliveryagentDetails = (obj) => {
    return async () => {
        try {
            const getDeliveryagentDetailsRequest = await axios.post(config.base_url + "customer/getDeliveryProfileDetails", obj)
                return getDeliveryagentDetailsRequest;
        } catch (e) {}
    }
}

export const getfitterDetails = (obj) => {
    return async () => {
        try {
            const getfitterDetailsRequest = await axios.post(config.base_url + "customer/getFitterProfileDetails", obj)
                return getfitterDetailsRequest;
        } catch (e) {}
    }
}